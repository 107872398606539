import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
};

export type AddDaysOffInput = {
  endTime: Scalars['String']['input'];
  startTime: Scalars['String']['input'];
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
  zipcode: Scalars['String']['output'];
};

export type AddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type AdminAppUser = {
  __typename?: 'AdminAppUser';
  email: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type AdminAthlete = {
  __typename?: 'AdminAthlete';
  slug: Scalars['String']['output'];
  storyblokId: Scalars['String']['output'];
  user: AdminAppUser;
};

export type AdminAthleteBookingListFilterInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerEmail?: InputMaybe<Scalars['String']['input']>;
  customerName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AdminAthleteBookingsListInput = {
  filter?: InputMaybe<AdminAthleteBookingListFilterInput>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
};

export type AdminAthleteList = {
  __typename?: 'AdminAthleteList';
  athletes: Array<AdminAthleteWithTotals>;
  totalPages: Scalars['Float']['output'];
};

export type AdminAthleteMembership = {
  __typename?: 'AdminAthleteMembership';
  athlete: AdminAthlete;
  cancellationAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  fee: Scalars['Float']['output'];
  firstSessionAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  member: AdminCustomer;
  membershipStatus: Scalars['String']['output'];
  membershipType: Scalars['String']['output'];
  nextRenewalAt?: Maybe<Scalars['DateTime']['output']>;
  price: Scalars['Float']['output'];
  renewals: Scalars['Float']['output'];
  sessionType: Scalars['String']['output'];
  total: Scalars['Float']['output'];
};

export type AdminAthleteMembershipList = {
  __typename?: 'AdminAthleteMembershipList';
  memberships: Array<AdminAthleteMembership>;
  totalPages: Scalars['Float']['output'];
};

export type AdminAthleteMembershipListFilterInput = {
  isWithCancellationAt?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AdminAthleteMembershipListInput = {
  filter?: InputMaybe<AdminAthleteMembershipListFilterInput>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
};

export type AdminAthleteTotals = {
  __typename?: 'AdminAthleteTotals';
  bookings: Scalars['Float']['output'];
  cancelled: Scalars['Float']['output'];
  fees: Scalars['Float']['output'];
  profit: Scalars['Float']['output'];
  revenue: Scalars['Float']['output'];
};

export type AdminAthleteWithTotals = {
  __typename?: 'AdminAthleteWithTotals';
  address: Scalars['String']['output'];
  college: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  email: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  hourlyRateGroup?: Maybe<Scalars['Float']['output']>;
  hourlyRateSingle?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  profilePicUrl?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  sport: Scalars['String']['output'];
  status: Scalars['String']['output'];
  storyblokId: Scalars['String']['output'];
  stripeId: Scalars['String']['output'];
  totals: AdminAthleteTotals;
};

export type AdminAthletesListFilterInput = {
  athleteEmail?: InputMaybe<Scalars['String']['input']>;
  athleteName?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  profileStatus?: InputMaybe<Array<Scalars['String']['input']>>;
  sport?: InputMaybe<Scalars['String']['input']>;
};

export type AdminAthletesListInput = {
  filter?: InputMaybe<AdminAthletesListFilterInput>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
};

export type AdminBooking = {
  __typename?: 'AdminBooking';
  BookingMeta?: Maybe<BookingMeta>;
  athlete: AdminAthlete;
  bookingStartTime?: Maybe<Scalars['DateTime']['output']>;
  bookingStatus: Scalars['String']['output'];
  bookingType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  fee: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  member: AdminCustomer;
  paymentId?: Maybe<Scalars['String']['output']>;
  payoutId?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  review?: Maybe<BookingReview>;
  total: Scalars['Float']['output'];
};

export type AdminBookingList = {
  __typename?: 'AdminBookingList';
  bookings: Array<AdminBooking>;
  totalPages: Scalars['Float']['output'];
};

export type AdminBusinessMetrics = {
  __typename?: 'AdminBusinessMetrics';
  totalActiveAthletes: Scalars['Float']['output'];
  totalAthletesProfit: Scalars['Float']['output'];
  totalBookings: Scalars['Float']['output'];
  totalCancelledBookings: Scalars['Float']['output'];
  totalCustomers: Scalars['Float']['output'];
  totalDeactivatedAthletes: Scalars['Float']['output'];
  totalDraftBookings: Scalars['Float']['output'];
  totalGroupBookings: Scalars['Float']['output'];
  totalInReviewAthletes: Scalars['Float']['output'];
  totalOnboardingAthletes: Scalars['Float']['output'];
  totalProfit: Scalars['Float']['output'];
  totalRevenue: Scalars['Float']['output'];
  totalSingleBookings: Scalars['Float']['output'];
};

export type AdminBusinessMetricsInput = {
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AdminClinicAttendant = {
  __typename?: 'AdminClinicAttendant';
  ClinicBookingMeta?: Maybe<ClinicBookingMeta>;
  fee: Scalars['Float']['output'];
  paymentId?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  ticketId: Scalars['String']['output'];
  user: AdminAppUser;
};

export type AdminClinicAttendantsInput = {
  clinicStoryblokId: Scalars['String']['input'];
};

export type AdminCustomer = {
  __typename?: 'AdminCustomer';
  phone: Scalars['String']['output'];
  user: AdminAppUser;
};

export type AdminCustomerList = {
  __typename?: 'AdminCustomerList';
  customers: Array<AdminCustomerWithTotals>;
  totalPages: Scalars['Float']['output'];
};

export type AdminCustomerListFilterInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerEmail?: InputMaybe<Scalars['String']['input']>;
  customerName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type AdminCustomerListInput = {
  filter?: InputMaybe<AdminCustomerListFilterInput>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
};

export type AdminCustomerTotals = {
  __typename?: 'AdminCustomerTotals';
  totalAmounts: Scalars['Float']['output'];
  totalCancelledBookings: Scalars['Float']['output'];
  totalClinicBookings: Scalars['Float']['output'];
  totalDrafts: Scalars['Float']['output'];
  totalGroupBookings: Scalars['Float']['output'];
  totalRefunded: Scalars['Float']['output'];
  totalSingleBookings: Scalars['Float']['output'];
};

export type AdminCustomerWithTotals = {
  __typename?: 'AdminCustomerWithTotals';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  totals: AdminCustomerTotals;
  user: AdminAppUser;
};

export type AppointmentListing = {
  __typename?: 'AppointmentListing';
  earnings: Scalars['Float']['output'];
  items: Array<Booking>;
  total: Scalars['Float']['output'];
};

export type Athlete = {
  __typename?: 'Athlete';
  address: Scalars['String']['output'];
  college: Scalars['String']['output'];
  email: Scalars['String']['output'];
  hasAthletePass: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isFullyBooked: Scalars['Boolean']['output'];
  minPrice: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  otherCollege?: Maybe<Scalars['String']['output']>;
  position: Scalars['String']['output'];
  positionDetails?: Maybe<Scalars['String']['output']>;
  profilePicUrl: Scalars['String']['output'];
  reviewScore?: Maybe<AthleteReviewScore>;
  secondarySport?: Maybe<AthleteSportData>;
  secondarySport2?: Maybe<AthleteSportData>;
  slug: Scalars['String']['output'];
  specifiedLocation: Scalars['String']['output'];
  sport?: Maybe<Scalars['String']['output']>;
  trainingSite: Scalars['String']['output'];
};

export type AthleteAccountCreation = {
  __typename?: 'AthleteAccountCreation';
  status: Scalars['String']['output'];
};

export type AthleteAccountDetails = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type AthleteAccountEdit = {
  __typename?: 'AthleteAccountEdit';
  status: Scalars['String']['output'];
};

export type AthleteAccountEditInput = {
  awards: Scalars['String']['input'];
  bio: Scalars['String']['input'];
  college: Scalars['String']['input'];
  experience: Scalars['String']['input'];
  instagram: Scalars['String']['input'];
  linkInBio?: InputMaybe<Scalars['String']['input']>;
  mediaConsent: Scalars['Boolean']['input'];
  otherCollege?: InputMaybe<Scalars['String']['input']>;
  photoGallery: Array<SbGalleryImageInput>;
  position: Scalars['String']['input'];
  positionDetails?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<SbImageInput>;
  secondarySport?: InputMaybe<AthleteSportDataInput>;
  secondarySport2?: InputMaybe<AthleteSportDataInput>;
  specifiedLocation?: InputMaybe<Scalars['String']['input']>;
  sport: Scalars['String']['input'];
  trainingSite: Scalars['String']['input'];
  twitter: Scalars['String']['input'];
  youtube: Scalars['String']['input'];
};

export type AthleteAthleteMembership = {
  __typename?: 'AthleteAthleteMembership';
  firstSessionAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  member: Member;
  name: Scalars['String']['output'];
  total: Scalars['Float']['output'];
};

export type AthleteAthletePass = {
  __typename?: 'AthleteAthletePass';
  completedSessions: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  member: Member;
  name: Scalars['String']['output'];
  totalSessions: Scalars['Float']['output'];
};

export type AthleteAvailability = {
  __typename?: 'AthleteAvailability';
  availableSlots: Array<TimeSlot>;
};

export type AthleteAvailabilityInput = {
  athleteStoryblokId: Scalars['String']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type AthleteBookingCustomerData = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  lessonType: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  position: Scalars['String']['input'];
  sport: Scalars['String']['input'];
  traineeAge: Scalars['Float']['input'];
  traineeName: Scalars['String']['input'];
};

export type AthleteBookingInput = {
  analyticsClientId: Scalars['String']['input'];
  athleteStoryblokId: Scalars['String']['input'];
  bookingType: Scalars['String']['input'];
  customerData: AthleteBookingCustomerData;
  numberOfParticipants?: InputMaybe<Scalars['Float']['input']>;
  price: Scalars['Float']['input'];
  timeSlotId: Scalars['String']['input'];
};

export type AthleteBookingPayment = {
  __typename?: 'AthleteBookingPayment';
  url: Scalars['String']['output'];
};

export type AthleteContactEdit = {
  __typename?: 'AthleteContactEdit';
  status: Scalars['String']['output'];
};

export type AthleteContactEditInput = {
  email: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type AthleteCreationInput = {
  accountDetails: AthleteAccountDetails;
  address: AddressInput;
  birthday: Scalars['String']['input'];
  coachingEligibility: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  graduationYear?: InputMaybe<Scalars['Float']['input']>;
  lastName: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
};

export type AthleteInput = {
  bookingId: Scalars['String']['input'];
};

export type AthleteListingInput = {
  availability?: InputMaybe<AvailabilityInput>;
  collegeName?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
  position?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sport?: InputMaybe<Scalars['String']['input']>;
};

export type AthleteMembership = {
  __typename?: 'AthleteMembership';
  athlete: Athlete;
  cancellationAt?: Maybe<Scalars['DateTime']['output']>;
  firstSessionAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  membershipStatus: Scalars['String']['output'];
  name: Scalars['String']['output'];
  total: Scalars['Float']['output'];
};

export type AthleteMembershipBookingInput = {
  analyticsClientId: Scalars['String']['input'];
  athleteStoryblokId: Scalars['String']['input'];
  bookingType: Scalars['String']['input'];
  customerData: AthleteBookingCustomerData;
  discount: Scalars['Float']['input'];
  numberOfParticipants?: InputMaybe<Scalars['Float']['input']>;
  price: Scalars['Float']['input'];
  timeSlotId: Scalars['String']['input'];
};

export type AthleteMembershipListingInput = {
  athleteStoryblokId?: InputMaybe<Scalars['String']['input']>;
};

export type AthleteModel = {
  __typename?: 'AthleteModel';
  athleteStatus: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  signedDocumentId?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  storyblokId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type AthletePass = {
  __typename?: 'AthletePass';
  athlete: Athlete;
  completedSessions: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  totalSessions: Scalars['Float']['output'];
};

export type AthletePassBookingFirstInput = {
  analyticsClientId: Scalars['String']['input'];
  athleteStoryblokId: Scalars['String']['input'];
  customerData: AthleteBookingCustomerData;
  discount: Scalars['Float']['input'];
  numberOfSessions: Scalars['Float']['input'];
  price: Scalars['Float']['input'];
  timeSlotId: Scalars['String']['input'];
};

export type AthletePassBookingInput = {
  athletePassBookingId: Scalars['String']['input'];
  customerData: AthleteBookingCustomerData;
  timeSlotId: Scalars['String']['input'];
};

export type AthletePassInput = {
  discount: Scalars['Float']['input'];
  isFavorite: Scalars['Boolean']['input'];
  numberOfSessions: Scalars['Float']['input'];
};

export type AthletePassListingInput = {
  athleteStoryblokId?: InputMaybe<Scalars['String']['input']>;
};

export type AthleteReviewScore = {
  __typename?: 'AthleteReviewScore';
  averageRating?: Maybe<Scalars['Float']['output']>;
  reviewCount: Scalars['Float']['output'];
};

export type AthleteSportData = {
  __typename?: 'AthleteSportData';
  position?: Maybe<Scalars['String']['output']>;
  positionDetails?: Maybe<Scalars['String']['output']>;
  sport: Scalars['String']['output'];
  yearsOfExperience?: Maybe<Scalars['String']['output']>;
};

export type AthleteSportDataInput = {
  position?: InputMaybe<Scalars['String']['input']>;
  positionDetails?: InputMaybe<Scalars['String']['input']>;
  sport: Scalars['String']['input'];
  yearsOfExperience?: InputMaybe<Scalars['String']['input']>;
};

export type AthleteUnavailability = {
  __typename?: 'AthleteUnavailability';
  unavailableSlots: Array<TimeSlot>;
};

export type AthleteUser = {
  __typename?: 'AthleteUser';
  athlete: AthleteModel;
  email: Scalars['String']['output'];
  emailVerified?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  role: Scalars['String']['output'];
};

export type AthleteWeeklyAvailability = {
  __typename?: 'AthleteWeeklyAvailability';
  availableDays: Array<AthleteWeeklyAvailabilityDay>;
};

export type AthleteWeeklyAvailabilityDay = {
  __typename?: 'AthleteWeeklyAvailabilityDay';
  dayOfWeek: Scalars['Int']['output'];
  duration: Scalars['Int']['output'];
  hasActiveMembership: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
};

export type AthletesListing = {
  __typename?: 'AthletesListing';
  items: Array<Athlete>;
  total: Scalars['Float']['output'];
};

export type AvailabilityInput = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type AvailabilitySubscription = {
  __typename?: 'AvailabilitySubscription';
  athleteId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AvailabilitySubscriptionInput = {
  athleteStoryblokId: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type Booking = {
  __typename?: 'Booking';
  BookingMeta: BookingMeta;
  MembershipBooking?: Maybe<MembershipBooking>;
  athlete: BookingAthlete;
  bookingStartTime: Scalars['DateTime']['output'];
  bookingStatus: Scalars['String']['output'];
  bookingType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  member: Member;
  numberOfParticipants: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type BookingAthlete = {
  __typename?: 'BookingAthlete';
  user: BookingUser;
};

export type BookingListing = {
  __typename?: 'BookingListing';
  items: Array<Booking>;
  total: Scalars['Float']['output'];
  waitingBookingCount: Scalars['Float']['output'];
};

export type BookingMeta = {
  __typename?: 'BookingMeta';
  lessonType: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  position: Scalars['String']['output'];
  sport: Scalars['String']['output'];
  traineeAge?: Maybe<Scalars['Float']['output']>;
  traineeName?: Maybe<Scalars['String']['output']>;
};

export type BookingReview = {
  __typename?: 'BookingReview';
  bookingId: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  rating: Scalars['Float']['output'];
};

export type BookingUser = {
  __typename?: 'BookingUser';
  name: Scalars['String']['output'];
};

export type CancelAppointmentInput = {
  bookingId: Scalars['String']['input'];
};

export type CancelMembershipInput = {
  membershipId: Scalars['String']['input'];
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  clientId: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  data: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isUnread: Scalars['Boolean']['output'];
  remoteId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ChatRoom = {
  __typename?: 'ChatRoom';
  athlete: Athlete;
  hasActiveAthletePass?: Maybe<Scalars['Boolean']['output']>;
  isDisabled: Scalars['Boolean']['output'];
  isLastMessageUnread?: Maybe<Scalars['Boolean']['output']>;
  lastUnreadMessage?: Maybe<Scalars['String']['output']>;
  lastUnreadMessageDate?: Maybe<Scalars['DateTime']['output']>;
  member: Member;
  name: Scalars['String']['output'];
  unreadMessagesCount: Scalars['Float']['output'];
};

export type Clinic = {
  __typename?: 'Clinic';
  activeUntil: Scalars['String']['output'];
  address?: Maybe<Address>;
  capacity: Scalars['Float']['output'];
  clinicStoryblokId: Scalars['String']['output'];
  dateTime: Scalars['String']['output'];
  description: Scalars['String']['output'];
  galleryUrls: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isFeatured: Scalars['Boolean']['output'];
  isPromoted: Scalars['Boolean']['output'];
  isSoldOut: Scalars['Boolean']['output'];
  location: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  slug: Scalars['String']['output'];
  sport: Scalars['String']['output'];
  title: Scalars['String']['output'];
  titleUrl: Scalars['String']['output'];
};

export type ClinicBookingCustomerData = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  position: Scalars['String']['input'];
  traineeAge: Scalars['Float']['input'];
  traineeName: Scalars['String']['input'];
};

export type ClinicBookingInput = {
  analyticsClientId: Scalars['String']['input'];
  clinicId: Scalars['String']['input'];
  customerData: ClinicBookingCustomerData;
  price: Scalars['Float']['input'];
};

export type ClinicBookingMeta = {
  __typename?: 'ClinicBookingMeta';
  message?: Maybe<Scalars['String']['output']>;
  position: Scalars['String']['output'];
  traineeAge?: Maybe<Scalars['Float']['output']>;
  traineeName?: Maybe<Scalars['String']['output']>;
};

export type ClinicBookingPayment = {
  __typename?: 'ClinicBookingPayment';
  url: Scalars['String']['output'];
};

export type ClinicListingInput = {
  isClinicsPage: Scalars['Boolean']['input'];
  limit: Scalars['Float']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Float']['input'];
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sport?: InputMaybe<Scalars['String']['input']>;
};

export type ClinicsListing = {
  __typename?: 'ClinicsListing';
  items: Array<Clinic>;
  total: Scalars['Float']['output'];
};

export type CoachingPreferencesEdit = {
  __typename?: 'CoachingPreferencesEdit';
  status: Scalars['String']['output'];
};

export type CoachingPreferencesEditInput = {
  athletePasses: Array<AthletePassInput>;
  emergencyName: Scalars['String']['input'];
  emergencyPhone: Scalars['String']['input'];
  sessions: Array<Session>;
};

export type ConfirmPasswordReset = {
  __typename?: 'ConfirmPasswordReset';
  status: Scalars['String']['output'];
};

export type ConfirmPasswordResetInput = {
  confirmationCode: Scalars['String']['input'];
  password: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type CreateTimeSlotInput = {
  startTime: Scalars['DateTime']['input'];
};

export type DeleteAthlete = {
  __typename?: 'DeleteAthlete';
  status: Scalars['String']['output'];
};

export type DeleteAthleteInput = {
  athleteId: Scalars['String']['input'];
};

export type DeleteTimeSlotInput = {
  slotId: Scalars['String']['input'];
};

export type EditDaysOffInput = {
  daysOffId: Scalars['String']['input'];
  endTime?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
};

export type GetHistoryInput = {
  channelName: Scalars['String']['input'];
  direction?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};

export type GetTimeSlotInput = {
  id: Scalars['String']['input'];
};

export type HasMemberSubscribedInput = {
  athleteStoryblokId: Scalars['String']['input'];
};

export type HistoryListing = {
  __typename?: 'HistoryListing';
  items: Array<ChatMessage>;
  total: Scalars['Float']['output'];
};

export type HourlyRate = {
  number: Scalars['Float']['input'];
  price: Scalars['String']['input'];
};

export type IsAthleteAvailabilityValidForMembershipInput = {
  athleteStoryblokId: Scalars['String']['input'];
  timeSlotId: Scalars['String']['input'];
};

export type MarkAsReadInput = {
  chatRoomName: Scalars['String']['input'];
};

export type Member = {
  __typename?: 'Member';
  phone: Scalars['String']['output'];
  user: User;
};

export type MemberAccountEdit = {
  __typename?: 'MemberAccountEdit';
  status: Scalars['String']['output'];
};

export type MemberBooking = {
  __typename?: 'MemberBooking';
  BookingMeta: BookingMeta;
  athlete: MemberBookingAthlete;
  bookingStartTime: Scalars['DateTime']['output'];
  bookingStatus: Scalars['String']['output'];
  bookingType: Scalars['String']['output'];
  id: Scalars['String']['output'];
  total: Scalars['Float']['output'];
};

export type MemberBookingAthlete = {
  __typename?: 'MemberBookingAthlete';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type MemberUpdateInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type MembershipBooking = {
  __typename?: 'MembershipBooking';
  firstSessionAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  membershipStatus: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addDaysOff: TimeSlot;
  bookAthlete: AthleteBookingPayment;
  bookAthleteMembership: AthleteBookingPayment;
  bookAthletePass: Booking;
  bookAthletePassFirst: AthleteBookingPayment;
  bookClinic: ClinicBookingPayment;
  cancelAppointment: Scalars['String']['output'];
  cancelMembership: Scalars['Boolean']['output'];
  changePassword: PasswordChange;
  confirmContractUpload: AthleteModel;
  confirmPasswordReset: ConfirmPasswordReset;
  createAthleteAccount: AthleteAccountCreation;
  createAvailabilitySubscription: AvailabilitySubscription;
  createSignDocumentInviteLink: SignDocumentInvite;
  createSignedResponse: SignedResponseCreation;
  createStripeAccount: StripeAccountActivation;
  createTimeSlot: MyAvailabilityTimeslot;
  deleteAthlete: DeleteAthlete;
  deleteTimeSlot: Scalars['String']['output'];
  editAthleteAccount: AthleteAccountEdit;
  editCoachingPreferences: CoachingPreferencesEdit;
  editDaysOff: Scalars['String']['output'];
  markAsRead: Scalars['Boolean']['output'];
  requestPasswordReset: RequestPasswordReset;
  rescheduleBookedTimeslot: Scalars['String']['output'];
  retryBookingPayment: AthleteBookingPayment;
  retryClinicPayment: ClinicBookingPayment;
  reviewBooking: BookingReview;
  sendForReview: AthleteModel;
  setAthleteUnavailability: AthleteUnavailability;
  setAthleteWeeklyAvailability: AthleteWeeklyAvailability;
  setTimeOff: Scalars['String']['output'];
  updateAthleteContact: AthleteContactEdit;
  updateMemberAccount: MemberAccountEdit;
};


export type MutationAddDaysOffArgs = {
  addDaysOffInput: AddDaysOffInput;
};


export type MutationBookAthleteArgs = {
  athleteBookingInput: AthleteBookingInput;
};


export type MutationBookAthleteMembershipArgs = {
  athleteMembershipBookingInput: AthleteMembershipBookingInput;
};


export type MutationBookAthletePassArgs = {
  athletePassBookingInput: AthletePassBookingInput;
};


export type MutationBookAthletePassFirstArgs = {
  athletePassBookingFirstInput: AthletePassBookingFirstInput;
};


export type MutationBookClinicArgs = {
  clinicBookingInput: ClinicBookingInput;
};


export type MutationCancelAppointmentArgs = {
  cancelAppointmentInput: CancelAppointmentInput;
};


export type MutationCancelMembershipArgs = {
  cancelMembershipInput: CancelMembershipInput;
};


export type MutationChangePasswordArgs = {
  passwordChange: PasswordChangeInput;
};


export type MutationConfirmPasswordResetArgs = {
  confirmPasswordResetInput: ConfirmPasswordResetInput;
};


export type MutationCreateAthleteAccountArgs = {
  athleteAccountInput: AthleteCreationInput;
};


export type MutationCreateAvailabilitySubscriptionArgs = {
  availabilitySubscriptionInput: AvailabilitySubscriptionInput;
};


export type MutationCreateSignedResponseArgs = {
  filename: Scalars['String']['input'];
};


export type MutationCreateTimeSlotArgs = {
  createTimeSlotInput: CreateTimeSlotInput;
};


export type MutationDeleteAthleteArgs = {
  deleteAthletInput: DeleteAthleteInput;
};


export type MutationDeleteTimeSlotArgs = {
  deleteTimeSlotInput: DeleteTimeSlotInput;
};


export type MutationEditAthleteAccountArgs = {
  athleteDetails: AthleteAccountEditInput;
};


export type MutationEditCoachingPreferencesArgs = {
  coachingPreferences: CoachingPreferencesEditInput;
};


export type MutationEditDaysOffArgs = {
  editDaysOffInput: EditDaysOffInput;
};


export type MutationMarkAsReadArgs = {
  markAsReadInput: MarkAsReadInput;
};


export type MutationRequestPasswordResetArgs = {
  requestPasswordResetInput: RequestPasswordResetInput;
};


export type MutationRescheduleBookedTimeslotArgs = {
  rescheduleBookedTimeslotInput: RescheduleBookedTimeslotInput;
};


export type MutationRetryBookingPaymentArgs = {
  retryBookingPaymentInput: RetryBookingPaymentInput;
};


export type MutationRetryClinicPaymentArgs = {
  retryBookingPaymentInput: RetryBookingPaymentInput;
};


export type MutationReviewBookingArgs = {
  reviewBookingInput: ReviewBookingInput;
};


export type MutationSetAthleteUnavailabilityArgs = {
  setUnavailabilityInput: SetUnavailabilityInput;
};


export type MutationSetAthleteWeeklyAvailabilityArgs = {
  setWeeklyAvailabilityInput: SetWeeklyAvailabilityInput;
};


export type MutationSetTimeOffArgs = {
  setTimeOffInput: SetTimeOffInput;
};


export type MutationUpdateAthleteContactArgs = {
  athleteContactDetails: AthleteContactEditInput;
};


export type MutationUpdateMemberAccountArgs = {
  memberDetails: MemberUpdateInput;
};

export type MyAvailability = {
  __typename?: 'MyAvailability';
  availableSlots: Array<MyAvailabilityTimeslot>;
  unavailableSlots: Array<TimeSlot>;
};

export type MyAvailabilityInput = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type MyAvailabilityTimeslot = {
  __typename?: 'MyAvailabilityTimeslot';
  booking?: Maybe<Booking>;
  endTime: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isDayOff: Scalars['Boolean']['output'];
  isTimeOff: Scalars['Boolean']['output'];
  startTime: Scalars['DateTime']['output'];
  type: Scalars['String']['output'];
};

export type MyMemberBookingsInput = {
  startDate: Scalars['DateTime']['input'];
};

export type PasswordChange = {
  __typename?: 'PasswordChange';
  status: Scalars['String']['output'];
};

export type PasswordChangeInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  adminAthleteListing: AdminAthleteList;
  adminAthleteMembershipList: AdminAthleteMembershipList;
  athlete: Athlete;
  athleteAvailability: AthleteAvailability;
  athleteFullyBooked: Scalars['Boolean']['output'];
  athleteListing: AthletesListing;
  athleteMembershipListing: Array<AthleteMembership>;
  athletePassListing: Array<AthletePass>;
  bookingsList: AdminBookingList;
  businessMetrics: AdminBusinessMetrics;
  clinicAttendants: Array<AdminClinicAttendant>;
  clinicListing: ClinicsListing;
  getChatRooms: Array<ChatRoom>;
  getHistory: HistoryListing;
  getUnreadCount: Scalars['Float']['output'];
  hasMemberSubscribed: Scalars['Boolean']['output'];
  isAthleteAvailabilityValidForMembership: Scalars['Boolean']['output'];
  me: AthleteUser;
  meMember: Member;
  memberStripePortalUrl: Scalars['String']['output'];
  members: AdminCustomerList;
  myAppointments: AppointmentListing;
  myAthleteMemberships: Array<AthleteAthleteMembership>;
  myAthletePasses: Array<AthleteAthletePass>;
  myAvailability: MyAvailability;
  myBookings: BookingListing;
  myMemberBookings: Array<MemberBooking>;
  myWeeklyAvailabilitySettings: AthleteWeeklyAvailability;
  timeslot: TimeSlot;
};


export type QueryAdminAthleteListingArgs = {
  adminAthletesListInput?: InputMaybe<AdminAthletesListInput>;
};


export type QueryAdminAthleteMembershipListArgs = {
  athleteBookingListInput?: InputMaybe<AdminAthleteMembershipListInput>;
};


export type QueryAthleteArgs = {
  athleteInput: AthleteInput;
};


export type QueryAthleteAvailabilityArgs = {
  athleteAvailabilityInput: AthleteAvailabilityInput;
};


export type QueryAthleteFullyBookedArgs = {
  athleteFullSlug: Scalars['String']['input'];
};


export type QueryAthleteListingArgs = {
  athleteListingInput: AthleteListingInput;
};


export type QueryAthleteMembershipListingArgs = {
  athleteMembershipListingInput: AthleteMembershipListingInput;
};


export type QueryAthletePassListingArgs = {
  athletePassListingInput: AthletePassListingInput;
};


export type QueryBookingsListArgs = {
  athleteBookingListInput?: InputMaybe<AdminAthleteBookingsListInput>;
};


export type QueryBusinessMetricsArgs = {
  metricsInput?: InputMaybe<AdminBusinessMetricsInput>;
};


export type QueryClinicAttendantsArgs = {
  clinicAttendantsInput: AdminClinicAttendantsInput;
};


export type QueryClinicListingArgs = {
  clinicListingInput: ClinicListingInput;
};


export type QueryGetHistoryArgs = {
  getHistoryInput: GetHistoryInput;
};


export type QueryHasMemberSubscribedArgs = {
  hasMemberSubscribedInput: HasMemberSubscribedInput;
};


export type QueryIsAthleteAvailabilityValidForMembershipArgs = {
  isAthleteAvailabilityValidForMembershipInput: IsAthleteAvailabilityValidForMembershipInput;
};


export type QueryMembersArgs = {
  customerListInput?: InputMaybe<AdminCustomerListInput>;
};


export type QueryMyAvailabilityArgs = {
  myAvailabilityInput: MyAvailabilityInput;
};


export type QueryMyMemberBookingsArgs = {
  myMemberBookingsInput: MyMemberBookingsInput;
};


export type QueryTimeslotArgs = {
  getTimeSlotInput: GetTimeSlotInput;
};

export type RequestPasswordReset = {
  __typename?: 'RequestPasswordReset';
  status: Scalars['String']['output'];
};

export type RequestPasswordResetInput = {
  email: Scalars['String']['input'];
};

export type RescheduleBookedTimeslotInput = {
  newTime: Scalars['DateTime']['input'];
  rescheduleAll?: InputMaybe<Scalars['Boolean']['input']>;
  timeslotId: Scalars['String']['input'];
};

export type RetryBookingPaymentInput = {
  bookingId: Scalars['String']['input'];
  timeslotId: Scalars['String']['input'];
};

export type ReviewBookingInput = {
  bookingId: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['Float']['input'];
};

export type SbGalleryImageInput = {
  id?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  overwriteFilename?: InputMaybe<Scalars['String']['input']>;
};

export type SbImageInput = {
  id: Scalars['Float']['input'];
  name: Scalars['String']['input'];
};

export type Session = {
  hourlyRate: HourlyRate;
  numberOfParticipants: Scalars['Float']['input'];
  type: Scalars['String']['input'];
};

export type SetAvailabilitySlotInput = {
  dayOfWeek: Scalars['Int']['input'];
  startTime: Scalars['String']['input'];
};

export type SetTimeOffInput = {
  isTimeOff: Scalars['Boolean']['input'];
  timeslotId: Scalars['String']['input'];
};

export type SetUnavailabilityInput = {
  createSlots: Array<TimeSlotInput>;
  deleteSlots: Array<Scalars['String']['input']>;
};

export type SetWeeklyAvailabilityInput = {
  createSlots: Array<SetAvailabilitySlotInput>;
  deleteSlots: Array<Scalars['String']['input']>;
};

export type SignDocumentInvite = {
  __typename?: 'SignDocumentInvite';
  url: Scalars['String']['output'];
};

export type SignedResponseCreation = {
  __typename?: 'SignedResponseCreation';
  fields: Scalars['JSONObject']['output'];
  id: Scalars['Float']['output'];
  postUrl: Scalars['String']['output'];
  prettyUrl: Scalars['String']['output'];
};

export type StripeAccountActivation = {
  __typename?: 'StripeAccountActivation';
  url: Scalars['String']['output'];
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  endTime: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  startTime: Scalars['DateTime']['output'];
};

export type TimeSlotInput = {
  endTime: Scalars['DateTime']['input'];
  startTime: Scalars['DateTime']['input'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AthleteFragmentFragment = { __typename?: 'Athlete', id: string, name: string, trainingSite: string, college: string, otherCollege?: string | null, specifiedLocation: string, position: string, positionDetails?: string | null, address: string, minPrice: number, slug: string, profilePicUrl: string, sport?: string | null, hasAthletePass: boolean, isFullyBooked: boolean, secondarySport?: { __typename?: 'AthleteSportData', sport: string, position?: string | null } | null, secondarySport2?: { __typename?: 'AthleteSportData', sport: string, position?: string | null } | null, reviewScore?: { __typename?: 'AthleteReviewScore', averageRating?: number | null, reviewCount: number } | null };

export type AthleteListingQueryVariables = Exact<{
  athleteListingInput: AthleteListingInput;
}>;


export type AthleteListingQuery = { __typename?: 'Query', athleteListing: { __typename?: 'AthletesListing', total: number, items: Array<{ __typename?: 'Athlete', id: string, name: string, trainingSite: string, college: string, otherCollege?: string | null, specifiedLocation: string, position: string, positionDetails?: string | null, address: string, minPrice: number, slug: string, profilePicUrl: string, sport?: string | null, hasAthletePass: boolean, isFullyBooked: boolean, secondarySport?: { __typename?: 'AthleteSportData', sport: string, position?: string | null } | null, secondarySport2?: { __typename?: 'AthleteSportData', sport: string, position?: string | null } | null, reviewScore?: { __typename?: 'AthleteReviewScore', averageRating?: number | null, reviewCount: number } | null }> } };

export type GetAthleteFullyBookedQueryVariables = Exact<{
  input: Scalars['String']['input'];
}>;


export type GetAthleteFullyBookedQuery = { __typename?: 'Query', athleteFullyBooked: boolean };

export type AthleteQueryVariables = Exact<{
  athleteInput: AthleteInput;
}>;


export type AthleteQuery = { __typename?: 'Query', athlete: { __typename?: 'Athlete', id: string, name: string, trainingSite: string, college: string, otherCollege?: string | null, specifiedLocation: string, position: string, positionDetails?: string | null, address: string, minPrice: number, slug: string, profilePicUrl: string, sport?: string | null, hasAthletePass: boolean, isFullyBooked: boolean, secondarySport?: { __typename?: 'AthleteSportData', sport: string, position?: string | null } | null, secondarySport2?: { __typename?: 'AthleteSportData', sport: string, position?: string | null } | null, reviewScore?: { __typename?: 'AthleteReviewScore', averageRating?: number | null, reviewCount: number } | null } };

export type MyAppointmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyAppointmentsQuery = { __typename?: 'Query', myAppointments: { __typename?: 'AppointmentListing', total: number, earnings: number, items: Array<{ __typename?: 'Booking', bookingType: string, bookingStartTime: any, total: number, member: { __typename?: 'Member', phone: string, user: { __typename?: 'User', name: string, email: string } }, BookingMeta: { __typename?: 'BookingMeta', lessonType: string, position: string, traineeName?: string | null, traineeAge?: number | null } }> } };

export type MyWeeklyAvailabilitySettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyWeeklyAvailabilitySettingsQuery = { __typename?: 'Query', myWeeklyAvailabilitySettings: { __typename?: 'AthleteWeeklyAvailability', availableDays: Array<{ __typename?: 'AthleteWeeklyAvailabilityDay', id: string, startTime: string, dayOfWeek: number, duration: number, hasActiveMembership: boolean }> } };

export type CreateAthleteAccountMutationVariables = Exact<{
  athleteAccountInput: AthleteCreationInput;
}>;


export type CreateAthleteAccountMutation = { __typename?: 'Mutation', createAthleteAccount: { __typename?: 'AthleteAccountCreation', status: string } };

export type EditAthleteAccountMutationVariables = Exact<{
  athleteDetails: AthleteAccountEditInput;
}>;


export type EditAthleteAccountMutation = { __typename?: 'Mutation', editAthleteAccount: { __typename?: 'AthleteAccountEdit', status: string } };

export type UpdateAthleteContactMutationVariables = Exact<{
  athleteContactDetails: AthleteContactEditInput;
}>;


export type UpdateAthleteContactMutation = { __typename?: 'Mutation', updateAthleteContact: { __typename?: 'AthleteContactEdit', status: string } };

export type CreateSignedResponseMutationVariables = Exact<{
  filename: Scalars['String']['input'];
}>;


export type CreateSignedResponseMutation = { __typename?: 'Mutation', createSignedResponse: { __typename?: 'SignedResponseCreation', fields: any, postUrl: string, prettyUrl: string, id: number } };

export type ChangePasswordMutationVariables = Exact<{
  passwordChange: PasswordChangeInput;
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: { __typename?: 'PasswordChange', status: string } };

export type EditCoachingPreferencesMutationVariables = Exact<{
  coachingPreferences: CoachingPreferencesEditInput;
}>;


export type EditCoachingPreferencesMutation = { __typename?: 'Mutation', editCoachingPreferences: { __typename?: 'CoachingPreferencesEdit', status: string } };

export type AthleteAvailabilityQueryVariables = Exact<{
  input: AthleteAvailabilityInput;
}>;


export type AthleteAvailabilityQuery = { __typename?: 'Query', athleteAvailability: { __typename?: 'AthleteAvailability', availableSlots: Array<{ __typename?: 'TimeSlot', id: string, startTime: any, endTime: any }> } };

export type IsAthleteAvailabilityValidForMembershipQueryVariables = Exact<{
  input: IsAthleteAvailabilityValidForMembershipInput;
}>;


export type IsAthleteAvailabilityValidForMembershipQuery = { __typename?: 'Query', isAthleteAvailabilityValidForMembership: boolean };

export type SetAthleteWeeklyAvailabilityMutationVariables = Exact<{
  setWeeklyAvailabilityInput: SetWeeklyAvailabilityInput;
}>;


export type SetAthleteWeeklyAvailabilityMutation = { __typename?: 'Mutation', setAthleteWeeklyAvailability: { __typename?: 'AthleteWeeklyAvailability', availableDays: Array<{ __typename?: 'AthleteWeeklyAvailabilityDay', id: string, startTime: string, dayOfWeek: number, duration: number, hasActiveMembership: boolean }> } };

export type MyAvailabilityQueryVariables = Exact<{
  myAvailabilityInput: MyAvailabilityInput;
}>;


export type MyAvailabilityQuery = { __typename?: 'Query', myAvailability: { __typename?: 'MyAvailability', availableSlots: Array<{ __typename?: 'MyAvailabilityTimeslot', id: string, type: string, startTime: any, endTime: any, isTimeOff: boolean, isDayOff: boolean, booking?: { __typename?: 'Booking', id: string, bookingStatus: string, bookingType: string, bookingStartTime: any, total: number, numberOfParticipants: number, member: { __typename?: 'Member', phone: string, user: { __typename?: 'User', name: string, email: string } }, BookingMeta: { __typename?: 'BookingMeta', sport: string, lessonType: string, position: string, message?: string | null, traineeName?: string | null, traineeAge?: number | null }, MembershipBooking?: { __typename?: 'MembershipBooking', id: string, membershipStatus: string, firstSessionAt: any } | null } | null }>, unavailableSlots: Array<{ __typename?: 'TimeSlot', id: string, startTime: any, endTime: any }> } };

export type CreateTimeSlotMutationVariables = Exact<{
  createTimeSlotInput: CreateTimeSlotInput;
}>;


export type CreateTimeSlotMutation = { __typename?: 'Mutation', createTimeSlot: { __typename?: 'MyAvailabilityTimeslot', id: string, type: string, startTime: any, endTime: any, isTimeOff: boolean, isDayOff: boolean, booking?: { __typename?: 'Booking', id: string, bookingStatus: string, bookingType: string, bookingStartTime: any, total: number, member: { __typename?: 'Member', user: { __typename?: 'User', name: string, email: string } }, BookingMeta: { __typename?: 'BookingMeta', lessonType: string, position: string, message?: string | null, traineeName?: string | null, traineeAge?: number | null } } | null } };

export type TimeslotQueryVariables = Exact<{
  getTimeSlotInput: GetTimeSlotInput;
}>;


export type TimeslotQuery = { __typename?: 'Query', timeslot: { __typename?: 'TimeSlot', id: string, startTime: any, endTime: any } };

export type RescheduleBookedTimeslotMutationVariables = Exact<{
  rescheduleBookedTimeslotInput: RescheduleBookedTimeslotInput;
}>;


export type RescheduleBookedTimeslotMutation = { __typename?: 'Mutation', rescheduleBookedTimeslot: string };

export type DeleteTimeSlotMutationVariables = Exact<{
  deleteTimeSlotInput: DeleteTimeSlotInput;
}>;


export type DeleteTimeSlotMutation = { __typename?: 'Mutation', deleteTimeSlot: string };

export type AddDaysOffMutationVariables = Exact<{
  addDaysOffInput: AddDaysOffInput;
}>;


export type AddDaysOffMutation = { __typename?: 'Mutation', addDaysOff: { __typename?: 'TimeSlot', id: string, startTime: any, endTime: any } };

export type EditDaysOffMutationVariables = Exact<{
  editDaysOffInput: EditDaysOffInput;
}>;


export type EditDaysOffMutation = { __typename?: 'Mutation', editDaysOff: string };

export type SetTimeOffMutationVariables = Exact<{
  setTimeOffInput: SetTimeOffInput;
}>;


export type SetTimeOffMutation = { __typename?: 'Mutation', setTimeOff: string };

export type AthleteMembershipListingQueryVariables = Exact<{
  input: AthleteMembershipListingInput;
}>;


export type AthleteMembershipListingQuery = { __typename?: 'Query', athleteMembershipListing: Array<{ __typename?: 'AthleteMembership', id: string, name: string, total: number, firstSessionAt: any, cancellationAt?: any | null, membershipStatus: string, athlete: { __typename?: 'Athlete', profilePicUrl: string, id: string, name: string, slug: string } }> };

export type MyAthleteMembershipsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyAthleteMembershipsQuery = { __typename?: 'Query', myAthleteMemberships: Array<{ __typename?: 'AthleteAthleteMembership', id: string, name: string, total: number, firstSessionAt: any, member: { __typename?: 'Member', user: { __typename?: 'User', name: string } } }> };

export type CancelMembershipMutationVariables = Exact<{
  input: CancelMembershipInput;
}>;


export type CancelMembershipMutation = { __typename?: 'Mutation', cancelMembership: boolean };

export type AthletePassListingQueryVariables = Exact<{
  athletePassListingInput: AthletePassListingInput;
}>;


export type AthletePassListingQuery = { __typename?: 'Query', athletePassListing: Array<{ __typename?: 'AthletePass', id: string, name: string, totalSessions: number, completedSessions: number, athlete: { __typename?: 'Athlete', profilePicUrl: string, id: string, name: string, slug: string } }> };

export type MyAthletePassesQueryVariables = Exact<{ [key: string]: never; }>;


export type MyAthletePassesQuery = { __typename?: 'Query', myAthletePasses: Array<{ __typename?: 'AthleteAthletePass', id: string, name: string, totalSessions: number, completedSessions: number, member: { __typename?: 'Member', user: { __typename?: 'User', name: string } } }> };

export type RequestPasswordResetMutationVariables = Exact<{
  requestPasswordResetInput: RequestPasswordResetInput;
}>;


export type RequestPasswordResetMutation = { __typename?: 'Mutation', requestPasswordReset: { __typename?: 'RequestPasswordReset', status: string } };

export type ConfirmPasswordResetMutationVariables = Exact<{
  confirmPasswordResetInput: ConfirmPasswordResetInput;
}>;


export type ConfirmPasswordResetMutation = { __typename?: 'Mutation', confirmPasswordReset: { __typename?: 'ConfirmPasswordReset', status: string } };

export type HasMemberSubscribedQueryVariables = Exact<{
  input: HasMemberSubscribedInput;
}>;


export type HasMemberSubscribedQuery = { __typename?: 'Query', hasMemberSubscribed: boolean };

export type CreateAvailabilitySubscriptionMutationVariables = Exact<{
  input: AvailabilitySubscriptionInput;
}>;


export type CreateAvailabilitySubscriptionMutation = { __typename?: 'Mutation', createAvailabilitySubscription: { __typename?: 'AvailabilitySubscription', id: string } };

export type BookAthleteMutationVariables = Exact<{
  input: AthleteBookingInput;
}>;


export type BookAthleteMutation = { __typename?: 'Mutation', bookAthlete: { __typename?: 'AthleteBookingPayment', url: string } };

export type ReviewBookingMutationVariables = Exact<{
  input: ReviewBookingInput;
}>;


export type ReviewBookingMutation = { __typename?: 'Mutation', reviewBooking: { __typename?: 'BookingReview', rating: number, message?: string | null } };

export type CancelAppointmentMutationVariables = Exact<{
  input: CancelAppointmentInput;
}>;


export type CancelAppointmentMutation = { __typename?: 'Mutation', cancelAppointment: string };

export type BookAthletePassMutationVariables = Exact<{
  input: AthletePassBookingInput;
}>;


export type BookAthletePassMutation = { __typename?: 'Mutation', bookAthletePass: { __typename?: 'Booking', BookingMeta: { __typename?: 'BookingMeta', lessonType: string, position: string, message?: string | null, location?: string | null, traineeName?: string | null, traineeAge?: number | null } } };

export type BookAthletePassFirstMutationVariables = Exact<{
  input: AthletePassBookingFirstInput;
}>;


export type BookAthletePassFirstMutation = { __typename?: 'Mutation', bookAthletePassFirst: { __typename?: 'AthleteBookingPayment', url: string } };

export type BookAthleteMembershipMutationVariables = Exact<{
  input: AthleteMembershipBookingInput;
}>;


export type BookAthleteMembershipMutation = { __typename?: 'Mutation', bookAthleteMembership: { __typename?: 'AthleteBookingPayment', url: string } };

export type BookClinicMutationVariables = Exact<{
  clinicBookingInput: ClinicBookingInput;
}>;


export type BookClinicMutation = { __typename?: 'Mutation', bookClinic: { __typename?: 'ClinicBookingPayment', url: string } };

export type GetChatRoomsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetChatRoomsQuery = { __typename?: 'Query', getChatRooms: Array<{ __typename?: 'ChatRoom', name: string, unreadMessagesCount: number, hasActiveAthletePass?: boolean | null, lastUnreadMessage?: string | null, lastUnreadMessageDate?: any | null, isLastMessageUnread?: boolean | null, isDisabled: boolean, member: { __typename?: 'Member', phone: string, user: { __typename?: 'User', name: string, email: string } }, athlete: { __typename?: 'Athlete', name: string, email: string, profilePicUrl: string } }> };

export type GetHistoryQueryVariables = Exact<{
  input: GetHistoryInput;
}>;


export type GetHistoryQuery = { __typename?: 'Query', getHistory: { __typename?: 'HistoryListing', total: number, items: Array<{ __typename?: 'ChatMessage', data: string, clientId: string, id: string, remoteId: string, isUnread: boolean, created: any, type: string }> } };

export type GetUnreadCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnreadCountQuery = { __typename?: 'Query', getUnreadCount: number };

export type MarkAsReadMutationVariables = Exact<{
  input: MarkAsReadInput;
}>;


export type MarkAsReadMutation = { __typename?: 'Mutation', markAsRead: boolean };

export type ClinicListingQueryVariables = Exact<{
  clinicListingInput: ClinicListingInput;
}>;


export type ClinicListingQuery = { __typename?: 'Query', clinicListing: { __typename?: 'ClinicsListing', total: number, items: Array<{ __typename?: 'Clinic', id: string, title: string, price: number, sport: string, dateTime: string, location: string, titleUrl: string, galleryUrls: Array<string>, description: string, slug: string, isFeatured: boolean, isPromoted: boolean, isSoldOut: boolean, activeUntil: string, address?: { __typename?: 'Address', state: string, city: string, street: string, country: string, zipcode: string } | null }> } };

export type MyBookingsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyBookingsQuery = { __typename?: 'Query', myBookings: { __typename?: 'BookingListing', total: number, waitingBookingCount: number, items: Array<{ __typename?: 'Booking', id: string, bookingType: string, bookingStartTime: any, total: number, member: { __typename?: 'Member', phone: string, user: { __typename?: 'User', name: string, email: string } }, athlete: { __typename?: 'BookingAthlete', user: { __typename?: 'BookingUser', name: string } }, BookingMeta: { __typename?: 'BookingMeta', lessonType: string, position: string, traineeName?: string | null, traineeAge?: number | null } }> } };

export type EditMemberAccountMutationVariables = Exact<{
  memberDetails: MemberUpdateInput;
}>;


export type EditMemberAccountMutation = { __typename?: 'Mutation', updateMemberAccount: { __typename?: 'MemberAccountEdit', status: string } };

export type MyMemberBookingsQueryVariables = Exact<{
  myMemberBookingsInput: MyMemberBookingsInput;
}>;


export type MyMemberBookingsQuery = { __typename?: 'Query', myMemberBookings: Array<{ __typename?: 'MemberBooking', id: string, bookingType: string, bookingStatus: string, bookingStartTime: any, total: number, athlete: { __typename?: 'MemberBookingAthlete', id: string, name: string, email: string, slug: string }, BookingMeta: { __typename?: 'BookingMeta', sport: string, lessonType: string, position: string, message?: string | null, traineeName?: string | null, traineeAge?: number | null } }> };

export type MemberStripePortalUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type MemberStripePortalUrlQuery = { __typename?: 'Query', memberStripePortalUrl: string };

export type CreateSignDocumentInviteLinkMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateSignDocumentInviteLinkMutation = { __typename?: 'Mutation', createSignDocumentInviteLink: { __typename?: 'SignDocumentInvite', url: string } };

export type CreateStripeAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateStripeAccountMutation = { __typename?: 'Mutation', createStripeAccount: { __typename?: 'StripeAccountActivation', url: string } };

export type ConfirmContractUploadMutationVariables = Exact<{ [key: string]: never; }>;


export type ConfirmContractUploadMutation = { __typename?: 'Mutation', confirmContractUpload: { __typename?: 'AthleteModel', id: string, athleteStatus: string } };

export type SendForReviewMutationVariables = Exact<{ [key: string]: never; }>;


export type SendForReviewMutation = { __typename?: 'Mutation', sendForReview: { __typename?: 'AthleteModel', id: string, athleteStatus: string } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'AthleteUser', id: string, name: string, image?: string | null, athlete: { __typename?: 'AthleteModel', id: string, athleteStatus: string, signedDocumentId?: string | null, slug: string } } };

export type MeMemberQueryVariables = Exact<{ [key: string]: never; }>;


export type MeMemberQuery = { __typename?: 'Query', meMember: { __typename?: 'Member', phone: string, user: { __typename?: 'User', name: string, email: string } } };

export const AthleteFragmentFragmentDoc = gql`
    fragment AthleteFragment on Athlete {
  id
  name
  trainingSite
  college
  otherCollege
  specifiedLocation
  position
  positionDetails
  address
  minPrice
  slug
  profilePicUrl
  sport
  secondarySport {
    sport
    position
  }
  secondarySport2 {
    sport
    position
  }
  hasAthletePass
  isFullyBooked
  reviewScore {
    averageRating
    reviewCount
  }
}
    `;
export const AthleteListingDocument = gql`
    query AthleteListing($athleteListingInput: AthleteListingInput!) {
  athleteListing(athleteListingInput: $athleteListingInput) {
    items {
      ...AthleteFragment
    }
    total
  }
}
    ${AthleteFragmentFragmentDoc}`;

/**
 * __useAthleteListingQuery__
 *
 * To run a query within a React component, call `useAthleteListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteListingQuery({
 *   variables: {
 *      athleteListingInput: // value for 'athleteListingInput'
 *   },
 * });
 */
export function useAthleteListingQuery(baseOptions: Apollo.QueryHookOptions<AthleteListingQuery, AthleteListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AthleteListingQuery, AthleteListingQueryVariables>(AthleteListingDocument, options);
      }
export function useAthleteListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AthleteListingQuery, AthleteListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AthleteListingQuery, AthleteListingQueryVariables>(AthleteListingDocument, options);
        }
export function useAthleteListingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AthleteListingQuery, AthleteListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AthleteListingQuery, AthleteListingQueryVariables>(AthleteListingDocument, options);
        }
export type AthleteListingQueryHookResult = ReturnType<typeof useAthleteListingQuery>;
export type AthleteListingLazyQueryHookResult = ReturnType<typeof useAthleteListingLazyQuery>;
export type AthleteListingSuspenseQueryHookResult = ReturnType<typeof useAthleteListingSuspenseQuery>;
export type AthleteListingQueryResult = Apollo.QueryResult<AthleteListingQuery, AthleteListingQueryVariables>;
export const GetAthleteFullyBookedDocument = gql`
    query GetAthleteFullyBooked($input: String!) {
  athleteFullyBooked(athleteFullSlug: $input)
}
    `;

/**
 * __useGetAthleteFullyBookedQuery__
 *
 * To run a query within a React component, call `useGetAthleteFullyBookedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAthleteFullyBookedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAthleteFullyBookedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAthleteFullyBookedQuery(baseOptions: Apollo.QueryHookOptions<GetAthleteFullyBookedQuery, GetAthleteFullyBookedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAthleteFullyBookedQuery, GetAthleteFullyBookedQueryVariables>(GetAthleteFullyBookedDocument, options);
      }
export function useGetAthleteFullyBookedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAthleteFullyBookedQuery, GetAthleteFullyBookedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAthleteFullyBookedQuery, GetAthleteFullyBookedQueryVariables>(GetAthleteFullyBookedDocument, options);
        }
export function useGetAthleteFullyBookedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAthleteFullyBookedQuery, GetAthleteFullyBookedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAthleteFullyBookedQuery, GetAthleteFullyBookedQueryVariables>(GetAthleteFullyBookedDocument, options);
        }
export type GetAthleteFullyBookedQueryHookResult = ReturnType<typeof useGetAthleteFullyBookedQuery>;
export type GetAthleteFullyBookedLazyQueryHookResult = ReturnType<typeof useGetAthleteFullyBookedLazyQuery>;
export type GetAthleteFullyBookedSuspenseQueryHookResult = ReturnType<typeof useGetAthleteFullyBookedSuspenseQuery>;
export type GetAthleteFullyBookedQueryResult = Apollo.QueryResult<GetAthleteFullyBookedQuery, GetAthleteFullyBookedQueryVariables>;
export const AthleteDocument = gql`
    query athlete($athleteInput: AthleteInput!) {
  athlete(athleteInput: $athleteInput) {
    ...AthleteFragment
  }
}
    ${AthleteFragmentFragmentDoc}`;

/**
 * __useAthleteQuery__
 *
 * To run a query within a React component, call `useAthleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteQuery({
 *   variables: {
 *      athleteInput: // value for 'athleteInput'
 *   },
 * });
 */
export function useAthleteQuery(baseOptions: Apollo.QueryHookOptions<AthleteQuery, AthleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AthleteQuery, AthleteQueryVariables>(AthleteDocument, options);
      }
export function useAthleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AthleteQuery, AthleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AthleteQuery, AthleteQueryVariables>(AthleteDocument, options);
        }
export function useAthleteSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AthleteQuery, AthleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AthleteQuery, AthleteQueryVariables>(AthleteDocument, options);
        }
export type AthleteQueryHookResult = ReturnType<typeof useAthleteQuery>;
export type AthleteLazyQueryHookResult = ReturnType<typeof useAthleteLazyQuery>;
export type AthleteSuspenseQueryHookResult = ReturnType<typeof useAthleteSuspenseQuery>;
export type AthleteQueryResult = Apollo.QueryResult<AthleteQuery, AthleteQueryVariables>;
export const MyAppointmentsDocument = gql`
    query MyAppointments {
  myAppointments {
    items {
      bookingType
      bookingStartTime
      total
      member {
        user {
          name
          email
        }
        phone
      }
      BookingMeta {
        lessonType
        position
        traineeName
        traineeAge
      }
    }
    total
    earnings
  }
}
    `;

/**
 * __useMyAppointmentsQuery__
 *
 * To run a query within a React component, call `useMyAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAppointmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<MyAppointmentsQuery, MyAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyAppointmentsQuery, MyAppointmentsQueryVariables>(MyAppointmentsDocument, options);
      }
export function useMyAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAppointmentsQuery, MyAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyAppointmentsQuery, MyAppointmentsQueryVariables>(MyAppointmentsDocument, options);
        }
export function useMyAppointmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyAppointmentsQuery, MyAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyAppointmentsQuery, MyAppointmentsQueryVariables>(MyAppointmentsDocument, options);
        }
export type MyAppointmentsQueryHookResult = ReturnType<typeof useMyAppointmentsQuery>;
export type MyAppointmentsLazyQueryHookResult = ReturnType<typeof useMyAppointmentsLazyQuery>;
export type MyAppointmentsSuspenseQueryHookResult = ReturnType<typeof useMyAppointmentsSuspenseQuery>;
export type MyAppointmentsQueryResult = Apollo.QueryResult<MyAppointmentsQuery, MyAppointmentsQueryVariables>;
export const MyWeeklyAvailabilitySettingsDocument = gql`
    query MyWeeklyAvailabilitySettings {
  myWeeklyAvailabilitySettings {
    availableDays {
      id
      startTime
      dayOfWeek
      duration
      hasActiveMembership
    }
  }
}
    `;

/**
 * __useMyWeeklyAvailabilitySettingsQuery__
 *
 * To run a query within a React component, call `useMyWeeklyAvailabilitySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyWeeklyAvailabilitySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyWeeklyAvailabilitySettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyWeeklyAvailabilitySettingsQuery(baseOptions?: Apollo.QueryHookOptions<MyWeeklyAvailabilitySettingsQuery, MyWeeklyAvailabilitySettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyWeeklyAvailabilitySettingsQuery, MyWeeklyAvailabilitySettingsQueryVariables>(MyWeeklyAvailabilitySettingsDocument, options);
      }
export function useMyWeeklyAvailabilitySettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyWeeklyAvailabilitySettingsQuery, MyWeeklyAvailabilitySettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyWeeklyAvailabilitySettingsQuery, MyWeeklyAvailabilitySettingsQueryVariables>(MyWeeklyAvailabilitySettingsDocument, options);
        }
export function useMyWeeklyAvailabilitySettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyWeeklyAvailabilitySettingsQuery, MyWeeklyAvailabilitySettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyWeeklyAvailabilitySettingsQuery, MyWeeklyAvailabilitySettingsQueryVariables>(MyWeeklyAvailabilitySettingsDocument, options);
        }
export type MyWeeklyAvailabilitySettingsQueryHookResult = ReturnType<typeof useMyWeeklyAvailabilitySettingsQuery>;
export type MyWeeklyAvailabilitySettingsLazyQueryHookResult = ReturnType<typeof useMyWeeklyAvailabilitySettingsLazyQuery>;
export type MyWeeklyAvailabilitySettingsSuspenseQueryHookResult = ReturnType<typeof useMyWeeklyAvailabilitySettingsSuspenseQuery>;
export type MyWeeklyAvailabilitySettingsQueryResult = Apollo.QueryResult<MyWeeklyAvailabilitySettingsQuery, MyWeeklyAvailabilitySettingsQueryVariables>;
export const CreateAthleteAccountDocument = gql`
    mutation CreateAthleteAccount($athleteAccountInput: AthleteCreationInput!) {
  createAthleteAccount(athleteAccountInput: $athleteAccountInput) {
    status
  }
}
    `;
export type CreateAthleteAccountMutationFn = Apollo.MutationFunction<CreateAthleteAccountMutation, CreateAthleteAccountMutationVariables>;

/**
 * __useCreateAthleteAccountMutation__
 *
 * To run a mutation, you first call `useCreateAthleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAthleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAthleteAccountMutation, { data, loading, error }] = useCreateAthleteAccountMutation({
 *   variables: {
 *      athleteAccountInput: // value for 'athleteAccountInput'
 *   },
 * });
 */
export function useCreateAthleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAthleteAccountMutation, CreateAthleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAthleteAccountMutation, CreateAthleteAccountMutationVariables>(CreateAthleteAccountDocument, options);
      }
export type CreateAthleteAccountMutationHookResult = ReturnType<typeof useCreateAthleteAccountMutation>;
export type CreateAthleteAccountMutationResult = Apollo.MutationResult<CreateAthleteAccountMutation>;
export type CreateAthleteAccountMutationOptions = Apollo.BaseMutationOptions<CreateAthleteAccountMutation, CreateAthleteAccountMutationVariables>;
export const EditAthleteAccountDocument = gql`
    mutation EditAthleteAccount($athleteDetails: AthleteAccountEditInput!) {
  editAthleteAccount(athleteDetails: $athleteDetails) {
    status
  }
}
    `;
export type EditAthleteAccountMutationFn = Apollo.MutationFunction<EditAthleteAccountMutation, EditAthleteAccountMutationVariables>;

/**
 * __useEditAthleteAccountMutation__
 *
 * To run a mutation, you first call `useEditAthleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAthleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAthleteAccountMutation, { data, loading, error }] = useEditAthleteAccountMutation({
 *   variables: {
 *      athleteDetails: // value for 'athleteDetails'
 *   },
 * });
 */
export function useEditAthleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<EditAthleteAccountMutation, EditAthleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditAthleteAccountMutation, EditAthleteAccountMutationVariables>(EditAthleteAccountDocument, options);
      }
export type EditAthleteAccountMutationHookResult = ReturnType<typeof useEditAthleteAccountMutation>;
export type EditAthleteAccountMutationResult = Apollo.MutationResult<EditAthleteAccountMutation>;
export type EditAthleteAccountMutationOptions = Apollo.BaseMutationOptions<EditAthleteAccountMutation, EditAthleteAccountMutationVariables>;
export const UpdateAthleteContactDocument = gql`
    mutation UpdateAthleteContact($athleteContactDetails: AthleteContactEditInput!) {
  updateAthleteContact(athleteContactDetails: $athleteContactDetails) {
    status
  }
}
    `;
export type UpdateAthleteContactMutationFn = Apollo.MutationFunction<UpdateAthleteContactMutation, UpdateAthleteContactMutationVariables>;

/**
 * __useUpdateAthleteContactMutation__
 *
 * To run a mutation, you first call `useUpdateAthleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAthleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAthleteContactMutation, { data, loading, error }] = useUpdateAthleteContactMutation({
 *   variables: {
 *      athleteContactDetails: // value for 'athleteContactDetails'
 *   },
 * });
 */
export function useUpdateAthleteContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAthleteContactMutation, UpdateAthleteContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAthleteContactMutation, UpdateAthleteContactMutationVariables>(UpdateAthleteContactDocument, options);
      }
export type UpdateAthleteContactMutationHookResult = ReturnType<typeof useUpdateAthleteContactMutation>;
export type UpdateAthleteContactMutationResult = Apollo.MutationResult<UpdateAthleteContactMutation>;
export type UpdateAthleteContactMutationOptions = Apollo.BaseMutationOptions<UpdateAthleteContactMutation, UpdateAthleteContactMutationVariables>;
export const CreateSignedResponseDocument = gql`
    mutation CreateSignedResponse($filename: String!) {
  createSignedResponse(filename: $filename) {
    fields
    postUrl
    prettyUrl
    id
  }
}
    `;
export type CreateSignedResponseMutationFn = Apollo.MutationFunction<CreateSignedResponseMutation, CreateSignedResponseMutationVariables>;

/**
 * __useCreateSignedResponseMutation__
 *
 * To run a mutation, you first call `useCreateSignedResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSignedResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSignedResponseMutation, { data, loading, error }] = useCreateSignedResponseMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useCreateSignedResponseMutation(baseOptions?: Apollo.MutationHookOptions<CreateSignedResponseMutation, CreateSignedResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSignedResponseMutation, CreateSignedResponseMutationVariables>(CreateSignedResponseDocument, options);
      }
export type CreateSignedResponseMutationHookResult = ReturnType<typeof useCreateSignedResponseMutation>;
export type CreateSignedResponseMutationResult = Apollo.MutationResult<CreateSignedResponseMutation>;
export type CreateSignedResponseMutationOptions = Apollo.BaseMutationOptions<CreateSignedResponseMutation, CreateSignedResponseMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($passwordChange: PasswordChangeInput!) {
  changePassword(passwordChange: $passwordChange) {
    status
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      passwordChange: // value for 'passwordChange'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const EditCoachingPreferencesDocument = gql`
    mutation EditCoachingPreferences($coachingPreferences: CoachingPreferencesEditInput!) {
  editCoachingPreferences(coachingPreferences: $coachingPreferences) {
    status
  }
}
    `;
export type EditCoachingPreferencesMutationFn = Apollo.MutationFunction<EditCoachingPreferencesMutation, EditCoachingPreferencesMutationVariables>;

/**
 * __useEditCoachingPreferencesMutation__
 *
 * To run a mutation, you first call `useEditCoachingPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCoachingPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCoachingPreferencesMutation, { data, loading, error }] = useEditCoachingPreferencesMutation({
 *   variables: {
 *      coachingPreferences: // value for 'coachingPreferences'
 *   },
 * });
 */
export function useEditCoachingPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<EditCoachingPreferencesMutation, EditCoachingPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCoachingPreferencesMutation, EditCoachingPreferencesMutationVariables>(EditCoachingPreferencesDocument, options);
      }
export type EditCoachingPreferencesMutationHookResult = ReturnType<typeof useEditCoachingPreferencesMutation>;
export type EditCoachingPreferencesMutationResult = Apollo.MutationResult<EditCoachingPreferencesMutation>;
export type EditCoachingPreferencesMutationOptions = Apollo.BaseMutationOptions<EditCoachingPreferencesMutation, EditCoachingPreferencesMutationVariables>;
export const AthleteAvailabilityDocument = gql`
    query AthleteAvailability($input: AthleteAvailabilityInput!) {
  athleteAvailability(athleteAvailabilityInput: $input) {
    availableSlots {
      id
      startTime
      endTime
    }
  }
}
    `;

/**
 * __useAthleteAvailabilityQuery__
 *
 * To run a query within a React component, call `useAthleteAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteAvailabilityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAthleteAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<AthleteAvailabilityQuery, AthleteAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AthleteAvailabilityQuery, AthleteAvailabilityQueryVariables>(AthleteAvailabilityDocument, options);
      }
export function useAthleteAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AthleteAvailabilityQuery, AthleteAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AthleteAvailabilityQuery, AthleteAvailabilityQueryVariables>(AthleteAvailabilityDocument, options);
        }
export function useAthleteAvailabilitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AthleteAvailabilityQuery, AthleteAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AthleteAvailabilityQuery, AthleteAvailabilityQueryVariables>(AthleteAvailabilityDocument, options);
        }
export type AthleteAvailabilityQueryHookResult = ReturnType<typeof useAthleteAvailabilityQuery>;
export type AthleteAvailabilityLazyQueryHookResult = ReturnType<typeof useAthleteAvailabilityLazyQuery>;
export type AthleteAvailabilitySuspenseQueryHookResult = ReturnType<typeof useAthleteAvailabilitySuspenseQuery>;
export type AthleteAvailabilityQueryResult = Apollo.QueryResult<AthleteAvailabilityQuery, AthleteAvailabilityQueryVariables>;
export const IsAthleteAvailabilityValidForMembershipDocument = gql`
    query IsAthleteAvailabilityValidForMembership($input: IsAthleteAvailabilityValidForMembershipInput!) {
  isAthleteAvailabilityValidForMembership(
    isAthleteAvailabilityValidForMembershipInput: $input
  )
}
    `;

/**
 * __useIsAthleteAvailabilityValidForMembershipQuery__
 *
 * To run a query within a React component, call `useIsAthleteAvailabilityValidForMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsAthleteAvailabilityValidForMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsAthleteAvailabilityValidForMembershipQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIsAthleteAvailabilityValidForMembershipQuery(baseOptions: Apollo.QueryHookOptions<IsAthleteAvailabilityValidForMembershipQuery, IsAthleteAvailabilityValidForMembershipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsAthleteAvailabilityValidForMembershipQuery, IsAthleteAvailabilityValidForMembershipQueryVariables>(IsAthleteAvailabilityValidForMembershipDocument, options);
      }
export function useIsAthleteAvailabilityValidForMembershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsAthleteAvailabilityValidForMembershipQuery, IsAthleteAvailabilityValidForMembershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsAthleteAvailabilityValidForMembershipQuery, IsAthleteAvailabilityValidForMembershipQueryVariables>(IsAthleteAvailabilityValidForMembershipDocument, options);
        }
export function useIsAthleteAvailabilityValidForMembershipSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IsAthleteAvailabilityValidForMembershipQuery, IsAthleteAvailabilityValidForMembershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IsAthleteAvailabilityValidForMembershipQuery, IsAthleteAvailabilityValidForMembershipQueryVariables>(IsAthleteAvailabilityValidForMembershipDocument, options);
        }
export type IsAthleteAvailabilityValidForMembershipQueryHookResult = ReturnType<typeof useIsAthleteAvailabilityValidForMembershipQuery>;
export type IsAthleteAvailabilityValidForMembershipLazyQueryHookResult = ReturnType<typeof useIsAthleteAvailabilityValidForMembershipLazyQuery>;
export type IsAthleteAvailabilityValidForMembershipSuspenseQueryHookResult = ReturnType<typeof useIsAthleteAvailabilityValidForMembershipSuspenseQuery>;
export type IsAthleteAvailabilityValidForMembershipQueryResult = Apollo.QueryResult<IsAthleteAvailabilityValidForMembershipQuery, IsAthleteAvailabilityValidForMembershipQueryVariables>;
export const SetAthleteWeeklyAvailabilityDocument = gql`
    mutation SetAthleteWeeklyAvailability($setWeeklyAvailabilityInput: SetWeeklyAvailabilityInput!) {
  setAthleteWeeklyAvailability(
    setWeeklyAvailabilityInput: $setWeeklyAvailabilityInput
  ) {
    availableDays {
      id
      startTime
      dayOfWeek
      duration
      hasActiveMembership
    }
  }
}
    `;
export type SetAthleteWeeklyAvailabilityMutationFn = Apollo.MutationFunction<SetAthleteWeeklyAvailabilityMutation, SetAthleteWeeklyAvailabilityMutationVariables>;

/**
 * __useSetAthleteWeeklyAvailabilityMutation__
 *
 * To run a mutation, you first call `useSetAthleteWeeklyAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAthleteWeeklyAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAthleteWeeklyAvailabilityMutation, { data, loading, error }] = useSetAthleteWeeklyAvailabilityMutation({
 *   variables: {
 *      setWeeklyAvailabilityInput: // value for 'setWeeklyAvailabilityInput'
 *   },
 * });
 */
export function useSetAthleteWeeklyAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<SetAthleteWeeklyAvailabilityMutation, SetAthleteWeeklyAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAthleteWeeklyAvailabilityMutation, SetAthleteWeeklyAvailabilityMutationVariables>(SetAthleteWeeklyAvailabilityDocument, options);
      }
export type SetAthleteWeeklyAvailabilityMutationHookResult = ReturnType<typeof useSetAthleteWeeklyAvailabilityMutation>;
export type SetAthleteWeeklyAvailabilityMutationResult = Apollo.MutationResult<SetAthleteWeeklyAvailabilityMutation>;
export type SetAthleteWeeklyAvailabilityMutationOptions = Apollo.BaseMutationOptions<SetAthleteWeeklyAvailabilityMutation, SetAthleteWeeklyAvailabilityMutationVariables>;
export const MyAvailabilityDocument = gql`
    query MyAvailability($myAvailabilityInput: MyAvailabilityInput!) {
  myAvailability(myAvailabilityInput: $myAvailabilityInput) {
    availableSlots {
      id
      type
      startTime
      endTime
      booking {
        id
        bookingStatus
        bookingType
        bookingStartTime
        total
        member {
          phone
          user {
            name
            email
          }
        }
        BookingMeta {
          sport
          lessonType
          position
          message
          traineeName
          traineeAge
        }
        MembershipBooking {
          id
          membershipStatus
          firstSessionAt
        }
        numberOfParticipants
      }
      isTimeOff
      isDayOff
    }
    unavailableSlots {
      id
      startTime
      endTime
    }
  }
}
    `;

/**
 * __useMyAvailabilityQuery__
 *
 * To run a query within a React component, call `useMyAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAvailabilityQuery({
 *   variables: {
 *      myAvailabilityInput: // value for 'myAvailabilityInput'
 *   },
 * });
 */
export function useMyAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<MyAvailabilityQuery, MyAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyAvailabilityQuery, MyAvailabilityQueryVariables>(MyAvailabilityDocument, options);
      }
export function useMyAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAvailabilityQuery, MyAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyAvailabilityQuery, MyAvailabilityQueryVariables>(MyAvailabilityDocument, options);
        }
export function useMyAvailabilitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyAvailabilityQuery, MyAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyAvailabilityQuery, MyAvailabilityQueryVariables>(MyAvailabilityDocument, options);
        }
export type MyAvailabilityQueryHookResult = ReturnType<typeof useMyAvailabilityQuery>;
export type MyAvailabilityLazyQueryHookResult = ReturnType<typeof useMyAvailabilityLazyQuery>;
export type MyAvailabilitySuspenseQueryHookResult = ReturnType<typeof useMyAvailabilitySuspenseQuery>;
export type MyAvailabilityQueryResult = Apollo.QueryResult<MyAvailabilityQuery, MyAvailabilityQueryVariables>;
export const CreateTimeSlotDocument = gql`
    mutation CreateTimeSlot($createTimeSlotInput: CreateTimeSlotInput!) {
  createTimeSlot(createTimeSlotInput: $createTimeSlotInput) {
    id
    type
    startTime
    endTime
    booking {
      id
      bookingStatus
      bookingType
      bookingStartTime
      total
      member {
        user {
          name
          email
        }
      }
      BookingMeta {
        lessonType
        position
        message
        traineeName
        traineeAge
      }
    }
    isTimeOff
    isDayOff
  }
}
    `;
export type CreateTimeSlotMutationFn = Apollo.MutationFunction<CreateTimeSlotMutation, CreateTimeSlotMutationVariables>;

/**
 * __useCreateTimeSlotMutation__
 *
 * To run a mutation, you first call `useCreateTimeSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeSlotMutation, { data, loading, error }] = useCreateTimeSlotMutation({
 *   variables: {
 *      createTimeSlotInput: // value for 'createTimeSlotInput'
 *   },
 * });
 */
export function useCreateTimeSlotMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimeSlotMutation, CreateTimeSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimeSlotMutation, CreateTimeSlotMutationVariables>(CreateTimeSlotDocument, options);
      }
export type CreateTimeSlotMutationHookResult = ReturnType<typeof useCreateTimeSlotMutation>;
export type CreateTimeSlotMutationResult = Apollo.MutationResult<CreateTimeSlotMutation>;
export type CreateTimeSlotMutationOptions = Apollo.BaseMutationOptions<CreateTimeSlotMutation, CreateTimeSlotMutationVariables>;
export const TimeslotDocument = gql`
    query Timeslot($getTimeSlotInput: GetTimeSlotInput!) {
  timeslot(getTimeSlotInput: $getTimeSlotInput) {
    id
    startTime
    endTime
  }
}
    `;

/**
 * __useTimeslotQuery__
 *
 * To run a query within a React component, call `useTimeslotQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeslotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeslotQuery({
 *   variables: {
 *      getTimeSlotInput: // value for 'getTimeSlotInput'
 *   },
 * });
 */
export function useTimeslotQuery(baseOptions: Apollo.QueryHookOptions<TimeslotQuery, TimeslotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeslotQuery, TimeslotQueryVariables>(TimeslotDocument, options);
      }
export function useTimeslotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeslotQuery, TimeslotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeslotQuery, TimeslotQueryVariables>(TimeslotDocument, options);
        }
export function useTimeslotSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TimeslotQuery, TimeslotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TimeslotQuery, TimeslotQueryVariables>(TimeslotDocument, options);
        }
export type TimeslotQueryHookResult = ReturnType<typeof useTimeslotQuery>;
export type TimeslotLazyQueryHookResult = ReturnType<typeof useTimeslotLazyQuery>;
export type TimeslotSuspenseQueryHookResult = ReturnType<typeof useTimeslotSuspenseQuery>;
export type TimeslotQueryResult = Apollo.QueryResult<TimeslotQuery, TimeslotQueryVariables>;
export const RescheduleBookedTimeslotDocument = gql`
    mutation RescheduleBookedTimeslot($rescheduleBookedTimeslotInput: RescheduleBookedTimeslotInput!) {
  rescheduleBookedTimeslot(
    rescheduleBookedTimeslotInput: $rescheduleBookedTimeslotInput
  )
}
    `;
export type RescheduleBookedTimeslotMutationFn = Apollo.MutationFunction<RescheduleBookedTimeslotMutation, RescheduleBookedTimeslotMutationVariables>;

/**
 * __useRescheduleBookedTimeslotMutation__
 *
 * To run a mutation, you first call `useRescheduleBookedTimeslotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleBookedTimeslotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleBookedTimeslotMutation, { data, loading, error }] = useRescheduleBookedTimeslotMutation({
 *   variables: {
 *      rescheduleBookedTimeslotInput: // value for 'rescheduleBookedTimeslotInput'
 *   },
 * });
 */
export function useRescheduleBookedTimeslotMutation(baseOptions?: Apollo.MutationHookOptions<RescheduleBookedTimeslotMutation, RescheduleBookedTimeslotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RescheduleBookedTimeslotMutation, RescheduleBookedTimeslotMutationVariables>(RescheduleBookedTimeslotDocument, options);
      }
export type RescheduleBookedTimeslotMutationHookResult = ReturnType<typeof useRescheduleBookedTimeslotMutation>;
export type RescheduleBookedTimeslotMutationResult = Apollo.MutationResult<RescheduleBookedTimeslotMutation>;
export type RescheduleBookedTimeslotMutationOptions = Apollo.BaseMutationOptions<RescheduleBookedTimeslotMutation, RescheduleBookedTimeslotMutationVariables>;
export const DeleteTimeSlotDocument = gql`
    mutation DeleteTimeSlot($deleteTimeSlotInput: DeleteTimeSlotInput!) {
  deleteTimeSlot(deleteTimeSlotInput: $deleteTimeSlotInput)
}
    `;
export type DeleteTimeSlotMutationFn = Apollo.MutationFunction<DeleteTimeSlotMutation, DeleteTimeSlotMutationVariables>;

/**
 * __useDeleteTimeSlotMutation__
 *
 * To run a mutation, you first call `useDeleteTimeSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeSlotMutation, { data, loading, error }] = useDeleteTimeSlotMutation({
 *   variables: {
 *      deleteTimeSlotInput: // value for 'deleteTimeSlotInput'
 *   },
 * });
 */
export function useDeleteTimeSlotMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeSlotMutation, DeleteTimeSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimeSlotMutation, DeleteTimeSlotMutationVariables>(DeleteTimeSlotDocument, options);
      }
export type DeleteTimeSlotMutationHookResult = ReturnType<typeof useDeleteTimeSlotMutation>;
export type DeleteTimeSlotMutationResult = Apollo.MutationResult<DeleteTimeSlotMutation>;
export type DeleteTimeSlotMutationOptions = Apollo.BaseMutationOptions<DeleteTimeSlotMutation, DeleteTimeSlotMutationVariables>;
export const AddDaysOffDocument = gql`
    mutation AddDaysOff($addDaysOffInput: AddDaysOffInput!) {
  addDaysOff(addDaysOffInput: $addDaysOffInput) {
    id
    startTime
    endTime
  }
}
    `;
export type AddDaysOffMutationFn = Apollo.MutationFunction<AddDaysOffMutation, AddDaysOffMutationVariables>;

/**
 * __useAddDaysOffMutation__
 *
 * To run a mutation, you first call `useAddDaysOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDaysOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDaysOffMutation, { data, loading, error }] = useAddDaysOffMutation({
 *   variables: {
 *      addDaysOffInput: // value for 'addDaysOffInput'
 *   },
 * });
 */
export function useAddDaysOffMutation(baseOptions?: Apollo.MutationHookOptions<AddDaysOffMutation, AddDaysOffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDaysOffMutation, AddDaysOffMutationVariables>(AddDaysOffDocument, options);
      }
export type AddDaysOffMutationHookResult = ReturnType<typeof useAddDaysOffMutation>;
export type AddDaysOffMutationResult = Apollo.MutationResult<AddDaysOffMutation>;
export type AddDaysOffMutationOptions = Apollo.BaseMutationOptions<AddDaysOffMutation, AddDaysOffMutationVariables>;
export const EditDaysOffDocument = gql`
    mutation EditDaysOff($editDaysOffInput: EditDaysOffInput!) {
  editDaysOff(editDaysOffInput: $editDaysOffInput)
}
    `;
export type EditDaysOffMutationFn = Apollo.MutationFunction<EditDaysOffMutation, EditDaysOffMutationVariables>;

/**
 * __useEditDaysOffMutation__
 *
 * To run a mutation, you first call `useEditDaysOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDaysOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDaysOffMutation, { data, loading, error }] = useEditDaysOffMutation({
 *   variables: {
 *      editDaysOffInput: // value for 'editDaysOffInput'
 *   },
 * });
 */
export function useEditDaysOffMutation(baseOptions?: Apollo.MutationHookOptions<EditDaysOffMutation, EditDaysOffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDaysOffMutation, EditDaysOffMutationVariables>(EditDaysOffDocument, options);
      }
export type EditDaysOffMutationHookResult = ReturnType<typeof useEditDaysOffMutation>;
export type EditDaysOffMutationResult = Apollo.MutationResult<EditDaysOffMutation>;
export type EditDaysOffMutationOptions = Apollo.BaseMutationOptions<EditDaysOffMutation, EditDaysOffMutationVariables>;
export const SetTimeOffDocument = gql`
    mutation SetTimeOff($setTimeOffInput: SetTimeOffInput!) {
  setTimeOff(setTimeOffInput: $setTimeOffInput)
}
    `;
export type SetTimeOffMutationFn = Apollo.MutationFunction<SetTimeOffMutation, SetTimeOffMutationVariables>;

/**
 * __useSetTimeOffMutation__
 *
 * To run a mutation, you first call `useSetTimeOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTimeOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTimeOffMutation, { data, loading, error }] = useSetTimeOffMutation({
 *   variables: {
 *      setTimeOffInput: // value for 'setTimeOffInput'
 *   },
 * });
 */
export function useSetTimeOffMutation(baseOptions?: Apollo.MutationHookOptions<SetTimeOffMutation, SetTimeOffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTimeOffMutation, SetTimeOffMutationVariables>(SetTimeOffDocument, options);
      }
export type SetTimeOffMutationHookResult = ReturnType<typeof useSetTimeOffMutation>;
export type SetTimeOffMutationResult = Apollo.MutationResult<SetTimeOffMutation>;
export type SetTimeOffMutationOptions = Apollo.BaseMutationOptions<SetTimeOffMutation, SetTimeOffMutationVariables>;
export const AthleteMembershipListingDocument = gql`
    query AthleteMembershipListing($input: AthleteMembershipListingInput!) {
  athleteMembershipListing(athleteMembershipListingInput: $input) {
    id
    name
    total
    firstSessionAt
    cancellationAt
    athlete {
      profilePicUrl
      id
      name
      slug
    }
    membershipStatus
  }
}
    `;

/**
 * __useAthleteMembershipListingQuery__
 *
 * To run a query within a React component, call `useAthleteMembershipListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteMembershipListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteMembershipListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAthleteMembershipListingQuery(baseOptions: Apollo.QueryHookOptions<AthleteMembershipListingQuery, AthleteMembershipListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AthleteMembershipListingQuery, AthleteMembershipListingQueryVariables>(AthleteMembershipListingDocument, options);
      }
export function useAthleteMembershipListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AthleteMembershipListingQuery, AthleteMembershipListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AthleteMembershipListingQuery, AthleteMembershipListingQueryVariables>(AthleteMembershipListingDocument, options);
        }
export function useAthleteMembershipListingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AthleteMembershipListingQuery, AthleteMembershipListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AthleteMembershipListingQuery, AthleteMembershipListingQueryVariables>(AthleteMembershipListingDocument, options);
        }
export type AthleteMembershipListingQueryHookResult = ReturnType<typeof useAthleteMembershipListingQuery>;
export type AthleteMembershipListingLazyQueryHookResult = ReturnType<typeof useAthleteMembershipListingLazyQuery>;
export type AthleteMembershipListingSuspenseQueryHookResult = ReturnType<typeof useAthleteMembershipListingSuspenseQuery>;
export type AthleteMembershipListingQueryResult = Apollo.QueryResult<AthleteMembershipListingQuery, AthleteMembershipListingQueryVariables>;
export const MyAthleteMembershipsDocument = gql`
    query MyAthleteMemberships {
  myAthleteMemberships {
    id
    name
    total
    firstSessionAt
    member {
      user {
        name
      }
    }
  }
}
    `;

/**
 * __useMyAthleteMembershipsQuery__
 *
 * To run a query within a React component, call `useMyAthleteMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAthleteMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAthleteMembershipsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyAthleteMembershipsQuery(baseOptions?: Apollo.QueryHookOptions<MyAthleteMembershipsQuery, MyAthleteMembershipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyAthleteMembershipsQuery, MyAthleteMembershipsQueryVariables>(MyAthleteMembershipsDocument, options);
      }
export function useMyAthleteMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAthleteMembershipsQuery, MyAthleteMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyAthleteMembershipsQuery, MyAthleteMembershipsQueryVariables>(MyAthleteMembershipsDocument, options);
        }
export function useMyAthleteMembershipsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyAthleteMembershipsQuery, MyAthleteMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyAthleteMembershipsQuery, MyAthleteMembershipsQueryVariables>(MyAthleteMembershipsDocument, options);
        }
export type MyAthleteMembershipsQueryHookResult = ReturnType<typeof useMyAthleteMembershipsQuery>;
export type MyAthleteMembershipsLazyQueryHookResult = ReturnType<typeof useMyAthleteMembershipsLazyQuery>;
export type MyAthleteMembershipsSuspenseQueryHookResult = ReturnType<typeof useMyAthleteMembershipsSuspenseQuery>;
export type MyAthleteMembershipsQueryResult = Apollo.QueryResult<MyAthleteMembershipsQuery, MyAthleteMembershipsQueryVariables>;
export const CancelMembershipDocument = gql`
    mutation CancelMembership($input: CancelMembershipInput!) {
  cancelMembership(cancelMembershipInput: $input)
}
    `;
export type CancelMembershipMutationFn = Apollo.MutationFunction<CancelMembershipMutation, CancelMembershipMutationVariables>;

/**
 * __useCancelMembershipMutation__
 *
 * To run a mutation, you first call `useCancelMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelMembershipMutation, { data, loading, error }] = useCancelMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelMembershipMutation(baseOptions?: Apollo.MutationHookOptions<CancelMembershipMutation, CancelMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelMembershipMutation, CancelMembershipMutationVariables>(CancelMembershipDocument, options);
      }
export type CancelMembershipMutationHookResult = ReturnType<typeof useCancelMembershipMutation>;
export type CancelMembershipMutationResult = Apollo.MutationResult<CancelMembershipMutation>;
export type CancelMembershipMutationOptions = Apollo.BaseMutationOptions<CancelMembershipMutation, CancelMembershipMutationVariables>;
export const AthletePassListingDocument = gql`
    query AthletePassListing($athletePassListingInput: AthletePassListingInput!) {
  athletePassListing(athletePassListingInput: $athletePassListingInput) {
    id
    name
    totalSessions
    completedSessions
    athlete {
      profilePicUrl
      id
      name
      slug
    }
  }
}
    `;

/**
 * __useAthletePassListingQuery__
 *
 * To run a query within a React component, call `useAthletePassListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletePassListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletePassListingQuery({
 *   variables: {
 *      athletePassListingInput: // value for 'athletePassListingInput'
 *   },
 * });
 */
export function useAthletePassListingQuery(baseOptions: Apollo.QueryHookOptions<AthletePassListingQuery, AthletePassListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AthletePassListingQuery, AthletePassListingQueryVariables>(AthletePassListingDocument, options);
      }
export function useAthletePassListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AthletePassListingQuery, AthletePassListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AthletePassListingQuery, AthletePassListingQueryVariables>(AthletePassListingDocument, options);
        }
export function useAthletePassListingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AthletePassListingQuery, AthletePassListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AthletePassListingQuery, AthletePassListingQueryVariables>(AthletePassListingDocument, options);
        }
export type AthletePassListingQueryHookResult = ReturnType<typeof useAthletePassListingQuery>;
export type AthletePassListingLazyQueryHookResult = ReturnType<typeof useAthletePassListingLazyQuery>;
export type AthletePassListingSuspenseQueryHookResult = ReturnType<typeof useAthletePassListingSuspenseQuery>;
export type AthletePassListingQueryResult = Apollo.QueryResult<AthletePassListingQuery, AthletePassListingQueryVariables>;
export const MyAthletePassesDocument = gql`
    query MyAthletePasses {
  myAthletePasses {
    id
    name
    totalSessions
    completedSessions
    member {
      user {
        name
      }
    }
  }
}
    `;

/**
 * __useMyAthletePassesQuery__
 *
 * To run a query within a React component, call `useMyAthletePassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAthletePassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAthletePassesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyAthletePassesQuery(baseOptions?: Apollo.QueryHookOptions<MyAthletePassesQuery, MyAthletePassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyAthletePassesQuery, MyAthletePassesQueryVariables>(MyAthletePassesDocument, options);
      }
export function useMyAthletePassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAthletePassesQuery, MyAthletePassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyAthletePassesQuery, MyAthletePassesQueryVariables>(MyAthletePassesDocument, options);
        }
export function useMyAthletePassesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyAthletePassesQuery, MyAthletePassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyAthletePassesQuery, MyAthletePassesQueryVariables>(MyAthletePassesDocument, options);
        }
export type MyAthletePassesQueryHookResult = ReturnType<typeof useMyAthletePassesQuery>;
export type MyAthletePassesLazyQueryHookResult = ReturnType<typeof useMyAthletePassesLazyQuery>;
export type MyAthletePassesSuspenseQueryHookResult = ReturnType<typeof useMyAthletePassesSuspenseQuery>;
export type MyAthletePassesQueryResult = Apollo.QueryResult<MyAthletePassesQuery, MyAthletePassesQueryVariables>;
export const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($requestPasswordResetInput: RequestPasswordResetInput!) {
  requestPasswordReset(requestPasswordResetInput: $requestPasswordResetInput) {
    status
  }
}
    `;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      requestPasswordResetInput: // value for 'requestPasswordResetInput'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, options);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export const ConfirmPasswordResetDocument = gql`
    mutation ConfirmPasswordReset($confirmPasswordResetInput: ConfirmPasswordResetInput!) {
  confirmPasswordReset(confirmPasswordResetInput: $confirmPasswordResetInput) {
    status
  }
}
    `;
export type ConfirmPasswordResetMutationFn = Apollo.MutationFunction<ConfirmPasswordResetMutation, ConfirmPasswordResetMutationVariables>;

/**
 * __useConfirmPasswordResetMutation__
 *
 * To run a mutation, you first call `useConfirmPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPasswordResetMutation, { data, loading, error }] = useConfirmPasswordResetMutation({
 *   variables: {
 *      confirmPasswordResetInput: // value for 'confirmPasswordResetInput'
 *   },
 * });
 */
export function useConfirmPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmPasswordResetMutation, ConfirmPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmPasswordResetMutation, ConfirmPasswordResetMutationVariables>(ConfirmPasswordResetDocument, options);
      }
export type ConfirmPasswordResetMutationHookResult = ReturnType<typeof useConfirmPasswordResetMutation>;
export type ConfirmPasswordResetMutationResult = Apollo.MutationResult<ConfirmPasswordResetMutation>;
export type ConfirmPasswordResetMutationOptions = Apollo.BaseMutationOptions<ConfirmPasswordResetMutation, ConfirmPasswordResetMutationVariables>;
export const HasMemberSubscribedDocument = gql`
    query HasMemberSubscribed($input: HasMemberSubscribedInput!) {
  hasMemberSubscribed(hasMemberSubscribedInput: $input)
}
    `;

/**
 * __useHasMemberSubscribedQuery__
 *
 * To run a query within a React component, call `useHasMemberSubscribedQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasMemberSubscribedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasMemberSubscribedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHasMemberSubscribedQuery(baseOptions: Apollo.QueryHookOptions<HasMemberSubscribedQuery, HasMemberSubscribedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasMemberSubscribedQuery, HasMemberSubscribedQueryVariables>(HasMemberSubscribedDocument, options);
      }
export function useHasMemberSubscribedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasMemberSubscribedQuery, HasMemberSubscribedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasMemberSubscribedQuery, HasMemberSubscribedQueryVariables>(HasMemberSubscribedDocument, options);
        }
export function useHasMemberSubscribedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HasMemberSubscribedQuery, HasMemberSubscribedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HasMemberSubscribedQuery, HasMemberSubscribedQueryVariables>(HasMemberSubscribedDocument, options);
        }
export type HasMemberSubscribedQueryHookResult = ReturnType<typeof useHasMemberSubscribedQuery>;
export type HasMemberSubscribedLazyQueryHookResult = ReturnType<typeof useHasMemberSubscribedLazyQuery>;
export type HasMemberSubscribedSuspenseQueryHookResult = ReturnType<typeof useHasMemberSubscribedSuspenseQuery>;
export type HasMemberSubscribedQueryResult = Apollo.QueryResult<HasMemberSubscribedQuery, HasMemberSubscribedQueryVariables>;
export const CreateAvailabilitySubscriptionDocument = gql`
    mutation CreateAvailabilitySubscription($input: AvailabilitySubscriptionInput!) {
  createAvailabilitySubscription(availabilitySubscriptionInput: $input) {
    id
  }
}
    `;
export type CreateAvailabilitySubscriptionMutationFn = Apollo.MutationFunction<CreateAvailabilitySubscriptionMutation, CreateAvailabilitySubscriptionMutationVariables>;

/**
 * __useCreateAvailabilitySubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateAvailabilitySubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAvailabilitySubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAvailabilitySubscriptionMutation, { data, loading, error }] = useCreateAvailabilitySubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAvailabilitySubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateAvailabilitySubscriptionMutation, CreateAvailabilitySubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAvailabilitySubscriptionMutation, CreateAvailabilitySubscriptionMutationVariables>(CreateAvailabilitySubscriptionDocument, options);
      }
export type CreateAvailabilitySubscriptionMutationHookResult = ReturnType<typeof useCreateAvailabilitySubscriptionMutation>;
export type CreateAvailabilitySubscriptionMutationResult = Apollo.MutationResult<CreateAvailabilitySubscriptionMutation>;
export type CreateAvailabilitySubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateAvailabilitySubscriptionMutation, CreateAvailabilitySubscriptionMutationVariables>;
export const BookAthleteDocument = gql`
    mutation BookAthlete($input: AthleteBookingInput!) {
  bookAthlete(athleteBookingInput: $input) {
    url
  }
}
    `;
export type BookAthleteMutationFn = Apollo.MutationFunction<BookAthleteMutation, BookAthleteMutationVariables>;

/**
 * __useBookAthleteMutation__
 *
 * To run a mutation, you first call `useBookAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookAthleteMutation, { data, loading, error }] = useBookAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookAthleteMutation(baseOptions?: Apollo.MutationHookOptions<BookAthleteMutation, BookAthleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookAthleteMutation, BookAthleteMutationVariables>(BookAthleteDocument, options);
      }
export type BookAthleteMutationHookResult = ReturnType<typeof useBookAthleteMutation>;
export type BookAthleteMutationResult = Apollo.MutationResult<BookAthleteMutation>;
export type BookAthleteMutationOptions = Apollo.BaseMutationOptions<BookAthleteMutation, BookAthleteMutationVariables>;
export const ReviewBookingDocument = gql`
    mutation ReviewBooking($input: ReviewBookingInput!) {
  reviewBooking(reviewBookingInput: $input) {
    rating
    message
  }
}
    `;
export type ReviewBookingMutationFn = Apollo.MutationFunction<ReviewBookingMutation, ReviewBookingMutationVariables>;

/**
 * __useReviewBookingMutation__
 *
 * To run a mutation, you first call `useReviewBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewBookingMutation, { data, loading, error }] = useReviewBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewBookingMutation(baseOptions?: Apollo.MutationHookOptions<ReviewBookingMutation, ReviewBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReviewBookingMutation, ReviewBookingMutationVariables>(ReviewBookingDocument, options);
      }
export type ReviewBookingMutationHookResult = ReturnType<typeof useReviewBookingMutation>;
export type ReviewBookingMutationResult = Apollo.MutationResult<ReviewBookingMutation>;
export type ReviewBookingMutationOptions = Apollo.BaseMutationOptions<ReviewBookingMutation, ReviewBookingMutationVariables>;
export const CancelAppointmentDocument = gql`
    mutation CancelAppointment($input: CancelAppointmentInput!) {
  cancelAppointment(cancelAppointmentInput: $input)
}
    `;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<CancelAppointmentMutation, CancelAppointmentMutationVariables>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAppointmentMutation, CancelAppointmentMutationVariables>(CancelAppointmentDocument, options);
      }
export type CancelAppointmentMutationHookResult = ReturnType<typeof useCancelAppointmentMutation>;
export type CancelAppointmentMutationResult = Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>;
export const BookAthletePassDocument = gql`
    mutation BookAthletePass($input: AthletePassBookingInput!) {
  bookAthletePass(athletePassBookingInput: $input) {
    BookingMeta {
      lessonType
      position
      message
      location
      traineeName
      traineeAge
    }
  }
}
    `;
export type BookAthletePassMutationFn = Apollo.MutationFunction<BookAthletePassMutation, BookAthletePassMutationVariables>;

/**
 * __useBookAthletePassMutation__
 *
 * To run a mutation, you first call `useBookAthletePassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookAthletePassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookAthletePassMutation, { data, loading, error }] = useBookAthletePassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookAthletePassMutation(baseOptions?: Apollo.MutationHookOptions<BookAthletePassMutation, BookAthletePassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookAthletePassMutation, BookAthletePassMutationVariables>(BookAthletePassDocument, options);
      }
export type BookAthletePassMutationHookResult = ReturnType<typeof useBookAthletePassMutation>;
export type BookAthletePassMutationResult = Apollo.MutationResult<BookAthletePassMutation>;
export type BookAthletePassMutationOptions = Apollo.BaseMutationOptions<BookAthletePassMutation, BookAthletePassMutationVariables>;
export const BookAthletePassFirstDocument = gql`
    mutation BookAthletePassFirst($input: AthletePassBookingFirstInput!) {
  bookAthletePassFirst(athletePassBookingFirstInput: $input) {
    url
  }
}
    `;
export type BookAthletePassFirstMutationFn = Apollo.MutationFunction<BookAthletePassFirstMutation, BookAthletePassFirstMutationVariables>;

/**
 * __useBookAthletePassFirstMutation__
 *
 * To run a mutation, you first call `useBookAthletePassFirstMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookAthletePassFirstMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookAthletePassFirstMutation, { data, loading, error }] = useBookAthletePassFirstMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookAthletePassFirstMutation(baseOptions?: Apollo.MutationHookOptions<BookAthletePassFirstMutation, BookAthletePassFirstMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookAthletePassFirstMutation, BookAthletePassFirstMutationVariables>(BookAthletePassFirstDocument, options);
      }
export type BookAthletePassFirstMutationHookResult = ReturnType<typeof useBookAthletePassFirstMutation>;
export type BookAthletePassFirstMutationResult = Apollo.MutationResult<BookAthletePassFirstMutation>;
export type BookAthletePassFirstMutationOptions = Apollo.BaseMutationOptions<BookAthletePassFirstMutation, BookAthletePassFirstMutationVariables>;
export const BookAthleteMembershipDocument = gql`
    mutation BookAthleteMembership($input: AthleteMembershipBookingInput!) {
  bookAthleteMembership(athleteMembershipBookingInput: $input) {
    url
  }
}
    `;
export type BookAthleteMembershipMutationFn = Apollo.MutationFunction<BookAthleteMembershipMutation, BookAthleteMembershipMutationVariables>;

/**
 * __useBookAthleteMembershipMutation__
 *
 * To run a mutation, you first call `useBookAthleteMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookAthleteMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookAthleteMembershipMutation, { data, loading, error }] = useBookAthleteMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookAthleteMembershipMutation(baseOptions?: Apollo.MutationHookOptions<BookAthleteMembershipMutation, BookAthleteMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookAthleteMembershipMutation, BookAthleteMembershipMutationVariables>(BookAthleteMembershipDocument, options);
      }
export type BookAthleteMembershipMutationHookResult = ReturnType<typeof useBookAthleteMembershipMutation>;
export type BookAthleteMembershipMutationResult = Apollo.MutationResult<BookAthleteMembershipMutation>;
export type BookAthleteMembershipMutationOptions = Apollo.BaseMutationOptions<BookAthleteMembershipMutation, BookAthleteMembershipMutationVariables>;
export const BookClinicDocument = gql`
    mutation BookClinic($clinicBookingInput: ClinicBookingInput!) {
  bookClinic(clinicBookingInput: $clinicBookingInput) {
    url
  }
}
    `;
export type BookClinicMutationFn = Apollo.MutationFunction<BookClinicMutation, BookClinicMutationVariables>;

/**
 * __useBookClinicMutation__
 *
 * To run a mutation, you first call `useBookClinicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookClinicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookClinicMutation, { data, loading, error }] = useBookClinicMutation({
 *   variables: {
 *      clinicBookingInput: // value for 'clinicBookingInput'
 *   },
 * });
 */
export function useBookClinicMutation(baseOptions?: Apollo.MutationHookOptions<BookClinicMutation, BookClinicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookClinicMutation, BookClinicMutationVariables>(BookClinicDocument, options);
      }
export type BookClinicMutationHookResult = ReturnType<typeof useBookClinicMutation>;
export type BookClinicMutationResult = Apollo.MutationResult<BookClinicMutation>;
export type BookClinicMutationOptions = Apollo.BaseMutationOptions<BookClinicMutation, BookClinicMutationVariables>;
export const GetChatRoomsDocument = gql`
    query GetChatRooms {
  getChatRooms {
    name
    member {
      user {
        name
        email
      }
      phone
    }
    athlete {
      name
      email
      profilePicUrl
    }
    unreadMessagesCount
    hasActiveAthletePass
    lastUnreadMessage
    lastUnreadMessageDate
    isLastMessageUnread
    isDisabled
  }
}
    `;

/**
 * __useGetChatRoomsQuery__
 *
 * To run a query within a React component, call `useGetChatRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatRoomsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChatRoomsQuery(baseOptions?: Apollo.QueryHookOptions<GetChatRoomsQuery, GetChatRoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChatRoomsQuery, GetChatRoomsQueryVariables>(GetChatRoomsDocument, options);
      }
export function useGetChatRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatRoomsQuery, GetChatRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChatRoomsQuery, GetChatRoomsQueryVariables>(GetChatRoomsDocument, options);
        }
export function useGetChatRoomsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetChatRoomsQuery, GetChatRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetChatRoomsQuery, GetChatRoomsQueryVariables>(GetChatRoomsDocument, options);
        }
export type GetChatRoomsQueryHookResult = ReturnType<typeof useGetChatRoomsQuery>;
export type GetChatRoomsLazyQueryHookResult = ReturnType<typeof useGetChatRoomsLazyQuery>;
export type GetChatRoomsSuspenseQueryHookResult = ReturnType<typeof useGetChatRoomsSuspenseQuery>;
export type GetChatRoomsQueryResult = Apollo.QueryResult<GetChatRoomsQuery, GetChatRoomsQueryVariables>;
export const GetHistoryDocument = gql`
    query GetHistory($input: GetHistoryInput!) {
  getHistory(getHistoryInput: $input) {
    total
    items {
      data
      clientId
      id
      remoteId
      isUnread
      created
      type
    }
  }
}
    `;

/**
 * __useGetHistoryQuery__
 *
 * To run a query within a React component, call `useGetHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetHistoryQuery, GetHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHistoryQuery, GetHistoryQueryVariables>(GetHistoryDocument, options);
      }
export function useGetHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHistoryQuery, GetHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHistoryQuery, GetHistoryQueryVariables>(GetHistoryDocument, options);
        }
export function useGetHistorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetHistoryQuery, GetHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHistoryQuery, GetHistoryQueryVariables>(GetHistoryDocument, options);
        }
export type GetHistoryQueryHookResult = ReturnType<typeof useGetHistoryQuery>;
export type GetHistoryLazyQueryHookResult = ReturnType<typeof useGetHistoryLazyQuery>;
export type GetHistorySuspenseQueryHookResult = ReturnType<typeof useGetHistorySuspenseQuery>;
export type GetHistoryQueryResult = Apollo.QueryResult<GetHistoryQuery, GetHistoryQueryVariables>;
export const GetUnreadCountDocument = gql`
    query GetUnreadCount {
  getUnreadCount
}
    `;

/**
 * __useGetUnreadCountQuery__
 *
 * To run a query within a React component, call `useGetUnreadCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnreadCountQuery(baseOptions?: Apollo.QueryHookOptions<GetUnreadCountQuery, GetUnreadCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnreadCountQuery, GetUnreadCountQueryVariables>(GetUnreadCountDocument, options);
      }
export function useGetUnreadCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnreadCountQuery, GetUnreadCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnreadCountQuery, GetUnreadCountQueryVariables>(GetUnreadCountDocument, options);
        }
export function useGetUnreadCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUnreadCountQuery, GetUnreadCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUnreadCountQuery, GetUnreadCountQueryVariables>(GetUnreadCountDocument, options);
        }
export type GetUnreadCountQueryHookResult = ReturnType<typeof useGetUnreadCountQuery>;
export type GetUnreadCountLazyQueryHookResult = ReturnType<typeof useGetUnreadCountLazyQuery>;
export type GetUnreadCountSuspenseQueryHookResult = ReturnType<typeof useGetUnreadCountSuspenseQuery>;
export type GetUnreadCountQueryResult = Apollo.QueryResult<GetUnreadCountQuery, GetUnreadCountQueryVariables>;
export const MarkAsReadDocument = gql`
    mutation MarkAsRead($input: MarkAsReadInput!) {
  markAsRead(markAsReadInput: $input)
}
    `;
export type MarkAsReadMutationFn = Apollo.MutationFunction<MarkAsReadMutation, MarkAsReadMutationVariables>;

/**
 * __useMarkAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsReadMutation, { data, loading, error }] = useMarkAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkAsReadMutation, MarkAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAsReadMutation, MarkAsReadMutationVariables>(MarkAsReadDocument, options);
      }
export type MarkAsReadMutationHookResult = ReturnType<typeof useMarkAsReadMutation>;
export type MarkAsReadMutationResult = Apollo.MutationResult<MarkAsReadMutation>;
export type MarkAsReadMutationOptions = Apollo.BaseMutationOptions<MarkAsReadMutation, MarkAsReadMutationVariables>;
export const ClinicListingDocument = gql`
    query ClinicListing($clinicListingInput: ClinicListingInput!) {
  clinicListing(clinicListingInput: $clinicListingInput) {
    items {
      id
      title
      price
      sport
      dateTime
      location
      titleUrl
      galleryUrls
      description
      slug
      isFeatured
      isPromoted
      isSoldOut
      activeUntil
      address {
        state
        city
        street
        country
        zipcode
      }
    }
    total
  }
}
    `;

/**
 * __useClinicListingQuery__
 *
 * To run a query within a React component, call `useClinicListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClinicListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClinicListingQuery({
 *   variables: {
 *      clinicListingInput: // value for 'clinicListingInput'
 *   },
 * });
 */
export function useClinicListingQuery(baseOptions: Apollo.QueryHookOptions<ClinicListingQuery, ClinicListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClinicListingQuery, ClinicListingQueryVariables>(ClinicListingDocument, options);
      }
export function useClinicListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClinicListingQuery, ClinicListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClinicListingQuery, ClinicListingQueryVariables>(ClinicListingDocument, options);
        }
export function useClinicListingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClinicListingQuery, ClinicListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClinicListingQuery, ClinicListingQueryVariables>(ClinicListingDocument, options);
        }
export type ClinicListingQueryHookResult = ReturnType<typeof useClinicListingQuery>;
export type ClinicListingLazyQueryHookResult = ReturnType<typeof useClinicListingLazyQuery>;
export type ClinicListingSuspenseQueryHookResult = ReturnType<typeof useClinicListingSuspenseQuery>;
export type ClinicListingQueryResult = Apollo.QueryResult<ClinicListingQuery, ClinicListingQueryVariables>;
export const MyBookingsDocument = gql`
    query MyBookings {
  myBookings {
    items {
      id
      bookingType
      bookingStartTime
      total
      member {
        user {
          name
          email
        }
        phone
      }
      athlete {
        user {
          name
        }
      }
      BookingMeta {
        lessonType
        position
        traineeName
        traineeAge
      }
    }
    total
    waitingBookingCount
  }
}
    `;

/**
 * __useMyBookingsQuery__
 *
 * To run a query within a React component, call `useMyBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBookingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyBookingsQuery(baseOptions?: Apollo.QueryHookOptions<MyBookingsQuery, MyBookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyBookingsQuery, MyBookingsQueryVariables>(MyBookingsDocument, options);
      }
export function useMyBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyBookingsQuery, MyBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyBookingsQuery, MyBookingsQueryVariables>(MyBookingsDocument, options);
        }
export function useMyBookingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyBookingsQuery, MyBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyBookingsQuery, MyBookingsQueryVariables>(MyBookingsDocument, options);
        }
export type MyBookingsQueryHookResult = ReturnType<typeof useMyBookingsQuery>;
export type MyBookingsLazyQueryHookResult = ReturnType<typeof useMyBookingsLazyQuery>;
export type MyBookingsSuspenseQueryHookResult = ReturnType<typeof useMyBookingsSuspenseQuery>;
export type MyBookingsQueryResult = Apollo.QueryResult<MyBookingsQuery, MyBookingsQueryVariables>;
export const EditMemberAccountDocument = gql`
    mutation EditMemberAccount($memberDetails: MemberUpdateInput!) {
  updateMemberAccount(memberDetails: $memberDetails) {
    status
  }
}
    `;
export type EditMemberAccountMutationFn = Apollo.MutationFunction<EditMemberAccountMutation, EditMemberAccountMutationVariables>;

/**
 * __useEditMemberAccountMutation__
 *
 * To run a mutation, you first call `useEditMemberAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMemberAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMemberAccountMutation, { data, loading, error }] = useEditMemberAccountMutation({
 *   variables: {
 *      memberDetails: // value for 'memberDetails'
 *   },
 * });
 */
export function useEditMemberAccountMutation(baseOptions?: Apollo.MutationHookOptions<EditMemberAccountMutation, EditMemberAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMemberAccountMutation, EditMemberAccountMutationVariables>(EditMemberAccountDocument, options);
      }
export type EditMemberAccountMutationHookResult = ReturnType<typeof useEditMemberAccountMutation>;
export type EditMemberAccountMutationResult = Apollo.MutationResult<EditMemberAccountMutation>;
export type EditMemberAccountMutationOptions = Apollo.BaseMutationOptions<EditMemberAccountMutation, EditMemberAccountMutationVariables>;
export const MyMemberBookingsDocument = gql`
    query MyMemberBookings($myMemberBookingsInput: MyMemberBookingsInput!) {
  myMemberBookings(myMemberBookingsInput: $myMemberBookingsInput) {
    id
    bookingType
    bookingStatus
    bookingStartTime
    total
    athlete {
      id
      name
      email
      slug
    }
    BookingMeta {
      sport
      lessonType
      position
      message
      traineeName
      traineeAge
    }
  }
}
    `;

/**
 * __useMyMemberBookingsQuery__
 *
 * To run a query within a React component, call `useMyMemberBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyMemberBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyMemberBookingsQuery({
 *   variables: {
 *      myMemberBookingsInput: // value for 'myMemberBookingsInput'
 *   },
 * });
 */
export function useMyMemberBookingsQuery(baseOptions: Apollo.QueryHookOptions<MyMemberBookingsQuery, MyMemberBookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyMemberBookingsQuery, MyMemberBookingsQueryVariables>(MyMemberBookingsDocument, options);
      }
export function useMyMemberBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyMemberBookingsQuery, MyMemberBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyMemberBookingsQuery, MyMemberBookingsQueryVariables>(MyMemberBookingsDocument, options);
        }
export function useMyMemberBookingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyMemberBookingsQuery, MyMemberBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyMemberBookingsQuery, MyMemberBookingsQueryVariables>(MyMemberBookingsDocument, options);
        }
export type MyMemberBookingsQueryHookResult = ReturnType<typeof useMyMemberBookingsQuery>;
export type MyMemberBookingsLazyQueryHookResult = ReturnType<typeof useMyMemberBookingsLazyQuery>;
export type MyMemberBookingsSuspenseQueryHookResult = ReturnType<typeof useMyMemberBookingsSuspenseQuery>;
export type MyMemberBookingsQueryResult = Apollo.QueryResult<MyMemberBookingsQuery, MyMemberBookingsQueryVariables>;
export const MemberStripePortalUrlDocument = gql`
    query MemberStripePortalUrl {
  memberStripePortalUrl
}
    `;

/**
 * __useMemberStripePortalUrlQuery__
 *
 * To run a query within a React component, call `useMemberStripePortalUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberStripePortalUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberStripePortalUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useMemberStripePortalUrlQuery(baseOptions?: Apollo.QueryHookOptions<MemberStripePortalUrlQuery, MemberStripePortalUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MemberStripePortalUrlQuery, MemberStripePortalUrlQueryVariables>(MemberStripePortalUrlDocument, options);
      }
export function useMemberStripePortalUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberStripePortalUrlQuery, MemberStripePortalUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MemberStripePortalUrlQuery, MemberStripePortalUrlQueryVariables>(MemberStripePortalUrlDocument, options);
        }
export function useMemberStripePortalUrlSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MemberStripePortalUrlQuery, MemberStripePortalUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MemberStripePortalUrlQuery, MemberStripePortalUrlQueryVariables>(MemberStripePortalUrlDocument, options);
        }
export type MemberStripePortalUrlQueryHookResult = ReturnType<typeof useMemberStripePortalUrlQuery>;
export type MemberStripePortalUrlLazyQueryHookResult = ReturnType<typeof useMemberStripePortalUrlLazyQuery>;
export type MemberStripePortalUrlSuspenseQueryHookResult = ReturnType<typeof useMemberStripePortalUrlSuspenseQuery>;
export type MemberStripePortalUrlQueryResult = Apollo.QueryResult<MemberStripePortalUrlQuery, MemberStripePortalUrlQueryVariables>;
export const CreateSignDocumentInviteLinkDocument = gql`
    mutation createSignDocumentInviteLink {
  createSignDocumentInviteLink {
    url
  }
}
    `;
export type CreateSignDocumentInviteLinkMutationFn = Apollo.MutationFunction<CreateSignDocumentInviteLinkMutation, CreateSignDocumentInviteLinkMutationVariables>;

/**
 * __useCreateSignDocumentInviteLinkMutation__
 *
 * To run a mutation, you first call `useCreateSignDocumentInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSignDocumentInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSignDocumentInviteLinkMutation, { data, loading, error }] = useCreateSignDocumentInviteLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateSignDocumentInviteLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateSignDocumentInviteLinkMutation, CreateSignDocumentInviteLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSignDocumentInviteLinkMutation, CreateSignDocumentInviteLinkMutationVariables>(CreateSignDocumentInviteLinkDocument, options);
      }
export type CreateSignDocumentInviteLinkMutationHookResult = ReturnType<typeof useCreateSignDocumentInviteLinkMutation>;
export type CreateSignDocumentInviteLinkMutationResult = Apollo.MutationResult<CreateSignDocumentInviteLinkMutation>;
export type CreateSignDocumentInviteLinkMutationOptions = Apollo.BaseMutationOptions<CreateSignDocumentInviteLinkMutation, CreateSignDocumentInviteLinkMutationVariables>;
export const CreateStripeAccountDocument = gql`
    mutation createStripeAccount {
  createStripeAccount {
    url
  }
}
    `;
export type CreateStripeAccountMutationFn = Apollo.MutationFunction<CreateStripeAccountMutation, CreateStripeAccountMutationVariables>;

/**
 * __useCreateStripeAccountMutation__
 *
 * To run a mutation, you first call `useCreateStripeAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeAccountMutation, { data, loading, error }] = useCreateStripeAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateStripeAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeAccountMutation, CreateStripeAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeAccountMutation, CreateStripeAccountMutationVariables>(CreateStripeAccountDocument, options);
      }
export type CreateStripeAccountMutationHookResult = ReturnType<typeof useCreateStripeAccountMutation>;
export type CreateStripeAccountMutationResult = Apollo.MutationResult<CreateStripeAccountMutation>;
export type CreateStripeAccountMutationOptions = Apollo.BaseMutationOptions<CreateStripeAccountMutation, CreateStripeAccountMutationVariables>;
export const ConfirmContractUploadDocument = gql`
    mutation confirmContractUpload {
  confirmContractUpload {
    id
    athleteStatus
  }
}
    `;
export type ConfirmContractUploadMutationFn = Apollo.MutationFunction<ConfirmContractUploadMutation, ConfirmContractUploadMutationVariables>;

/**
 * __useConfirmContractUploadMutation__
 *
 * To run a mutation, you first call `useConfirmContractUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmContractUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmContractUploadMutation, { data, loading, error }] = useConfirmContractUploadMutation({
 *   variables: {
 *   },
 * });
 */
export function useConfirmContractUploadMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmContractUploadMutation, ConfirmContractUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmContractUploadMutation, ConfirmContractUploadMutationVariables>(ConfirmContractUploadDocument, options);
      }
export type ConfirmContractUploadMutationHookResult = ReturnType<typeof useConfirmContractUploadMutation>;
export type ConfirmContractUploadMutationResult = Apollo.MutationResult<ConfirmContractUploadMutation>;
export type ConfirmContractUploadMutationOptions = Apollo.BaseMutationOptions<ConfirmContractUploadMutation, ConfirmContractUploadMutationVariables>;
export const SendForReviewDocument = gql`
    mutation sendForReview {
  sendForReview {
    id
    athleteStatus
  }
}
    `;
export type SendForReviewMutationFn = Apollo.MutationFunction<SendForReviewMutation, SendForReviewMutationVariables>;

/**
 * __useSendForReviewMutation__
 *
 * To run a mutation, you first call `useSendForReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendForReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendForReviewMutation, { data, loading, error }] = useSendForReviewMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendForReviewMutation(baseOptions?: Apollo.MutationHookOptions<SendForReviewMutation, SendForReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendForReviewMutation, SendForReviewMutationVariables>(SendForReviewDocument, options);
      }
export type SendForReviewMutationHookResult = ReturnType<typeof useSendForReviewMutation>;
export type SendForReviewMutationResult = Apollo.MutationResult<SendForReviewMutation>;
export type SendForReviewMutationOptions = Apollo.BaseMutationOptions<SendForReviewMutation, SendForReviewMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    name
    image
    athlete {
      id
      athleteStatus
      signedDocumentId
      slug
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MeMemberDocument = gql`
    query MeMember {
  meMember {
    phone
    user {
      name
      email
    }
  }
}
    `;

/**
 * __useMeMemberQuery__
 *
 * To run a query within a React component, call `useMeMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeMemberQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeMemberQuery(baseOptions?: Apollo.QueryHookOptions<MeMemberQuery, MeMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeMemberQuery, MeMemberQueryVariables>(MeMemberDocument, options);
      }
export function useMeMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeMemberQuery, MeMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeMemberQuery, MeMemberQueryVariables>(MeMemberDocument, options);
        }
export function useMeMemberSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeMemberQuery, MeMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeMemberQuery, MeMemberQueryVariables>(MeMemberDocument, options);
        }
export type MeMemberQueryHookResult = ReturnType<typeof useMeMemberQuery>;
export type MeMemberLazyQueryHookResult = ReturnType<typeof useMeMemberLazyQuery>;
export type MeMemberSuspenseQueryHookResult = ReturnType<typeof useMeMemberSuspenseQuery>;
export type MeMemberQueryResult = Apollo.QueryResult<MeMemberQuery, MeMemberQueryVariables>;