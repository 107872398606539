import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'lib/utils'
import { LoadingIcon } from 'components/icons/LoadingIcon'

const buttonVariants = cva(
  'inline-flex items-center justify-center gap-2 whitespace-nowrap cursor-pointer rounded-lg text-base text-center font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300',
  {
    variants: {
      variant: {
        default: 'bg-slate-900 text-slate-50 dark:bg-slate-50 dark:text-slate-900',
        destructive:
          'bg-red-500 text-slate-50 hover:bg-red-500/90 dark:bg-red-900 dark:text-slate-50 dark:hover:bg-red-900/90',
        outline:
          'border border-slate-200 bg-transparent hover:border-brand-mint text-white hover:text-brand-mint dark:border-slate-800 dark:bg-slate-950 dark:hover:bg-slate-800 dark:hover:text-slate-50',
        secondary:
          'bg-slate-100 text-slate-900 hover:bg-slate-100/80 dark:bg-slate-800 dark:text-slate-50 dark:hover:bg-slate-800/80',
        ghost: 'dark:hover:bg-slate-800 dark:hover:text-slate-50',
        link: 'text-slate-900 underline-offset-4 hover:underline dark:text-slate-50 !p-0',
        icon: 'border',
      },
      size: {
        default: 'h-10 px-4 py-2 text-[14px]',
        sm: 'h-9 rounded-lg px-3',
        lg: 'h-11 rounded-lg px-4',
        xl: 'py-[14px] px-7',
        '2xl': 'h-16 py-5 px-7',
        icon: 'h-10 w-10',
      },
      colorType: {
        mint: undefined,
        white: undefined,
        slate: undefined,
        red: undefined,
      },
    },
    compoundVariants: [
      {
        variant: 'link',
        colorType: 'white',
        class: 'text-white',
      },
      {
        variant: 'link',
        colorType: 'slate',
        class: 'text-slate-800',
      },
      {
        variant: 'default',
        colorType: 'white',
        class: 'bg-white text-slate-900 hover:bg-mint-50',
      },
      {
        variant: 'default',
        colorType: 'mint',
        class: 'bg-brand-mint text-slate-950 hover:bg-mint-50',
      },
      {
        variant: 'default',
        colorType: 'slate',
        class: 'bg-slate-800 text-white hover:bg-slate-900',
      },
      {
        variant: 'outline',
        colorType: 'slate',
        class: 'text-slate-800 border-slate-800',
      },
      {
        variant: 'outline',
        colorType: 'white',
        class: 'text-slate-100 border-slate-100',
      },
      {
        variant: 'outline',
        colorType: 'red',
        class: 'text-red-400 border-slate-700 hover:text-red-500 hover:border-red-500',
      },
      {
        variant: 'ghost',
        colorType: 'white',
        class: 'text-white hover:text-brand-mint',
      },
      {
        variant: 'ghost',
        colorType: 'slate',
        class: 'text-slate-400 hover:text-brand-mint',
      },
      {
        variant: 'icon',
        colorType: 'slate',
        class: 'text-slate-400 border-slate-200 hover:bg-brand-mint hover:text-brand-slate',
      },
    ],
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  isLoading?: boolean
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      colorType,
      size,
      asChild = false,
      isLoading,
      children,
      leftIcon,
      rightIcon,
      disabled,
      ...props
    },
    ref,
  ) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className, colorType }), 'min-w-fit')}
        disabled={disabled || isLoading}
        ref={ref}
        {...props}
      >
        {!isLoading && leftIcon}
        {isLoading && <LoadingIcon className="h-4 w-4 animate-spin" />}
        {!isLoading && children}
        {!isLoading && rightIcon}
      </Comp>
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
