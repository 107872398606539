import { SVGProps } from 'react'

export const LoadingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_535_83487)">
      <mask id="mask0_535_83487" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <path d="M0 0H24V24H0V0Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_535_83487)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0002 3C11.0002 2.44772 11.448 2 12.0002 2C17.5231 2 22.0002 6.47715 22.0002 12C22.0002 17.5228 17.5231 22 12.0002 22C6.47739 22 2.00024 17.5228 2.00024 12C2.00024 11.4477 2.44796 11 3.00024 11C3.55253 11 4.00024 11.4477 4.00024 12C4.00024 16.4183 7.58196 20 12.0002 20C16.4185 20 20.0002 16.4183 20.0002 12C20.0002 7.58172 16.4185 4 12.0002 4C11.448 4 11.0002 3.55228 11.0002 3Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_535_83487">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
